import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import DataTable from "react-data-table-component";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { IconContext } from "react-icons";
import { BsPlus } from "react-icons/bs";

const Email_template = () => {
  const customCell = (row, func2) => (
    <Link to={`/admin/email_templete/update_email_template/${row.id}`}>
      {" "}
      <svg
        className="my-auto d-flex"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Vector"
          d="M0 14.2505V18H3.74949L14.808 6.9415L11.0585 3.19201L0 14.2505ZM17.7076 4.0419C17.8003 3.94939 17.8738 3.83952 17.924 3.71856C17.9742 3.59761 18 3.46794 18 3.33699C18 3.20604 17.9742 3.07638 17.924 2.95542C17.8738 2.83446 17.8003 2.72459 17.7076 2.63209L15.3679 0.292404C15.2754 0.199713 15.1655 0.126176 15.0446 0.0760011C14.9236 0.0258265 14.794 0 14.663 0C14.5321 0 14.4024 0.0258265 14.2814 0.0760011C14.1605 0.126176 14.0506 0.199713 13.9581 0.292404L12.1284 2.12216L15.8778 5.87165L17.7076 4.0419Z"
          fill={`${row.orderstatus == 3 ? "rgb(120,120,120)" : "#BCBCBC"}`}
        />
      </svg>
    </Link>
  );

  const column = [
    {
      name: "Nr.",
      selector: (row) => row.id,
      sortable: true,
      responsive: "sm",
    },
    {
      name: "Titel",
      selector: (row) => row.email_title,
      sortable: true,
      responsive: "sm",
    },

    {
      name: "Aktion",
      selector: (row) => customCell(row),
      sortable: true,
      responsive: "sm",
    },
  ];

  const customCell1 = (row, func2) => (
    <Link to={`/admin/chat_templete/update_chat_template/${row.id}`}>
      <i className="fa-regular fa-pen-to-square mx-1"></i>
    </Link>
  );

  const column1 = [
    {
      name: "Nr.",
      selector: (row) => row.id,
      sortable: true,
      responsive: "sm",
    },
    {
      name: "Titel",
      selector: (row) => row.msg_title,
      sortable: true,
      responsive: "sm",
    },

    {
      name: "Aktion",
      selector: (row) => customCell1(row),
      sortable: true,
      responsive: "sm",
    },
  ];

  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("token");

  // email api
  const [chatdata, setchatData] = useState([]);
  const navigate1 = useNavigate();
  useEffect(() => {
    // console.log('chat');rs
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/chat/template`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        // console.log(res);
        setIsLoaded(true);
        setchatData(res.data.data);
        // toast.success(res.data.message, { toastId: "unique-random-text-xAu9C9-" })
      })
      .catch((res) => {
        setIsLoaded(true);
        toast.error(res.response.message);
      });
  }, []);

  // email api
  const [emaildata, setemailData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/email/template`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setIsLoaded(true);
        setemailData(res.data.data);
        // toast.success(res.data.message, { toastId: "unique-random-text-xAu9C9-" })
      })
      .catch((res) => {
        setIsLoaded(true);
        toast.error(res.response.message);
      });
  }, []);
  const CustomEmptyState = () => (
    <div className="custom-empty-state p-3">Keine Einträge vorhanden</div>
  );

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <img src={"https://i.gifer.com/VAyR.gif"} className="loader" />;
  } else {
    return (
      <>
        <div
          className="main-body position-relative"
          style={{ zIndex: "12" }}
          id="root1"
        >
          <div className="order-header d-flex div">
            <IconContext.Provider
              value={{ className: "shared-class", size: 40 }}
            >
              <BsFillEnvelopeFill />
            </IconContext.Provider>

            <h3 className="my-auto ms-4">E-Mail</h3>
          </div>

          <div className=" datatable">
            <div className="admin-dashboard emai_dashboarddata">
              <div id="admin-dashboarddata">
                <DataTable
                  columns={column}
                  data={emaildata}
                  noHeader={true}
                  pagination={true}
                  paginationPerPage={10}
                  paginationComponentOptions={{
                    rowsPerPageText: "Anzahl/Seite:",
                    rangeSeparatorText: "von",
                  }}
                  paginationRowsPerPageOptions={[5, 10]}
                  noDataComponent={<CustomEmptyState />}
                  onRowClicked={(row) => {
                    navigate(
                      `/admin/email_templete/update_email_template/${row.id}`
                    );
                  }}
                  theme="solarized"
                />
              </div>
            </div>
          </div>
        </div>

        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </>
    );
  }
};
export default Email_template;
