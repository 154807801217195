import * as Yup from 'yup';


const AddCourseSchema = Yup.object({

    lessonname: Yup.string().required('Lesson Name...'),
    // lessondesc: Yup.string().required('Lesson description...')
    // files: Yup.string().required('Course description...')
    // topicname: Yup.string().required('Course description...')
    // topictime: Yup.string().required('Course description...')
    
})

export default AddCourseSchema;