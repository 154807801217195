
import React from 'react'
import { Outlet } from 'react-router-dom'
import Usermenu from './Usermenu';
import Notification from './Notification/Notification';
import "./Usermenu.css"
export default function Userlayout() {
  return (
    <>
      {/* <Notification/> */}
      <div className='user-row'>
      <Usermenu/>
      <Outlet />   
      </div>
      
    </>
  )
}

