import * as Yup from 'yup';


const AddCourseSchema = Yup.object({

    coursename: Yup.string().required('Course Name...'),
    coursedesc: Yup.string().required('Course description...')
    
})

export default AddCourseSchema;