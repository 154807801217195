import React, { useEffect, useState } from "react";
import "./course.css";
import bulb from "../../../Assets/menuicon/Group.png";
import { Link, useNavigate } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import styled from "styled-components";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import eye from "../../../Assets/images/Vector (6).png";

const StyledCell = styled.div`
  &.Standard {
    border-radius: 755.494px;
    background: rgba(255, 255, 255, 0.2);
    font-family: Poppins;
    width: 120px;
    font-size: 16px;
    padding: 3px 0px;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
  &.Hoch {
    border-radius: 755.494px;
    background: #fff;
    text-align: center;
    color: #000;
    padding: 3px 0px;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 120px;
  }
  &.Neuer {
    border-radius: 75px;
    background: rgba(255, 255, 255, 0.2);
    width: 204px;
    height: 31px;
    padding: 3px;
    font-size: 16px;
    white-space: nowrap;

    height: 31px;
    text-align: center;
  }
  &.Wird {
    border-radius: 75px;
    width: 204px;
    background: linear-gradient(
      92deg,
      rgba(192, 222, 96, 0.2) 0.85%,
      rgba(96, 213, 222, 0.2) 110.38%
    );
    padding: 3px 6px;
    font-size: 16px;
    white-space: nowrap;

    text-align: center;
  }
  &.Abgeschlossen {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 75px;
    width: 204px;
    padding: 3px 39px;
    font-size: 16px;
    height: 31px;
    text-align: center;
    white-space: nowrap;
  }
`;

const Course = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const click = (course_id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Möchten Sie diesen Datensatz löschen?",
      buttons: [
        {
          label: "Ja",
          onClick: () => deletecourse(course_id),
        },
        {
          label: "Nein",
        },
      ],
    });
  };

  const deletecourse = (course_id) => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/course/remove/${course_id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        toast.success(res.data.message, {
          toastId: "unique-random-text-xAu9C9-",
        });
        allcourse();
      })
      .catch((res) => {
        toast.error(res.response.data.message);
      });
  };
  const handleRedirect = (course_name, course_id) => {
    navigate(`lessons?course=${course_name}&&course_id=${course_id}`);
  };
  const customCell = (row, func2) =>
    getRole === "1" ? (
      <div className="d-flex">
        <div  onClick={()=>{
        navigate(
          `lessons?course=${row.course_name}&&course_id=${row.course_id}`
        );
      }} className="me-3 my-auto">
          {/* {/* <button
            onClick={() => {
              handleRedirect(row.course_name, row.course_id);
            }}
            className="neworder-btn my-auto"
          >
            Kurs ansehen
          </button> 
          <Link
            to={`lessons?course=${row.course_name}&&course_id=${row.course_id}`}
            className="d-flex align-items-center"
          >
            <img src={eye} height="18" width="25" />
          </Link> */}
        </div>
        <div className="ms-2 my-auto">
          <svg
            className="my-auto d-flex"
            onClick={() => func2(row.course_id)}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Frame">
              <path
                id="Vector"
                d="M5.99878 19C5.99878 20.1 6.89878 21 7.99878 21H15.9988C17.0988 21 17.9988 20.1 17.9988 19V7H5.99878V19ZM18.9988 4H15.4988L14.4988 3H9.49878L8.49878 4H4.99878V6H18.9988V4Z"
                fill={`${
                  row.orderstatus == 3 ? "rgb(120,120,120)" : "#BCBCBC"
                }`}
              />
            </g>
          </svg>
        </div>
      </div>
    ) : (
      <button
        onClick={() => {
          handleRedirect(row.course_name, row.course_id);
        }}
        className="neworder-btn w-100 my-auto"
      >
        Kurs ansehen
      </button>
    );

  const allcourse = () => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/course/get/all`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        // console.log(res.data.data);
        const coursesWithEmpCount = res.data.data.map((course) => {
          const empCount = course.emp ? course.emp.split(",").length : 0;
          return { ...course, empCount };
        });
        setData(coursesWithEmpCount);
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.message);
      });
  };

  const empCourse = () => {
    const EmpId = localStorage.getItem("id");
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/course/get/byemp`,
      headers: {
        authorization: `Bearer ${token}`,
      },
      data: { EmpId: EmpId },
    })
      .then((res) => {
        // console.log(res.data);
        const coursesWithEmpCount = res.data.data.map((course) => {
          return { ...course };
        });
        setData(coursesWithEmpCount);
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.message);
      });
  };

  const getRole = localStorage.getItem("role");

  useEffect(() => {
    if (getRole === "1") {
      allcourse();
    } else if (getRole === "2") {
      empCourse();
    }
  }, [getRole]);

  const [data, setData] = useState([]);

  const column = [
    {
      name: "",
      cell: (row) => <StyledCell className=""></StyledCell>,
      width: "2%",
    },
    {
      name: "Name",
      sortable: false,
      cell: (row) => <StyledCell onClick={()=>{
        navigate(
          `lessons?course=${row.course_name}&&course_id=${row.course_id}`
        );
      }} className="">{row.course_name}</StyledCell>,
      // width: "53%",
    },
    {
      name: "Lektionen",
      sortable: false,
      cell: (row) => (
        <StyledCell onClick={()=>{
          navigate(
            `lessons?course=${row.course_name}&&course_id=${row.course_id}`
          );
        }} className="lessoncount">{row.lessonCount}</StyledCell>
      ),
      // width: "15%",
    },
    getRole === "1"
      ? {
          name: "Mitglieder",
          sortable: false,
          cell: (row) => (
            <StyledCell onClick={()=>{
              navigate(
                `lessons?course=${row.course_name}&&course_id=${row.course_id}`
              );
            }} className="empcount">{row.empCount}</StyledCell>
          ),
          // width: "15%",
        }
      : {
          name: "",
          cell: (row) => <StyledCell className=""></StyledCell>,
          width: "0%",
        },
    {
      name: "Action",
      selector: (row) => customCell(row, click),
      sortable: false,
    //   width: "15%",
    },
  ];

  const CustomEmptyState = () => (
    <div className="custom-empty-state p-3">Keine Eintrage vorhanden</div>
  );

  return (
    <>
      <div className="main-body" id="root1">
        <div
          className="course-header position-relative d-flex div"
          style={{ zIndex: "12" }}
        >
          <img src={bulb} className="my-auto" width={35} height={45} />
          <h3>Kurse</h3>
          {getRole === "1" ? (
            <Link to="addcourse" className="order-btn">
              Neuer Kurs
            </Link>
          ) : null}
        </div>

        <div className="course-datatable">
          <div className="admin-dashboard">
            <div id="admin-dashboarddata">
              <DataTable
                columns={column}
                data={data}
                noHeader={true}
                pagination={true}
                paginationPerPage={20}
                noDataComponent={<CustomEmptyState />}
                paginationComponentOptions={{
                  rowsPerPageText: "Anzahl/Seite:",
                  rangeSeparatorText: "von",
                }}
                paginationRowsPerPageOptions={[20, 30, 40]}
                conditionalRowStyles={[
                  {
                    when: (row) => row.orderstatus === 3,
                    style: {
                      background: "rgba(255, 255, 255, 0.04)",
                    },
                  },
                ]}
                onRowClicked={(row) => {
                  navigate(
                    `lessons?course=${row.course_name}&&course_id=${row.course_id}`
                  );
                }}
                theme="solarized"
              />
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default Course;
