import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./neworder.css";
import { useFormik } from "formik";
import jQuery from "jquery";
import NeworderRegistrationSchema from "./OrderRegistrationSchima";
import { toast, ToastContainer } from "react-toastify";
import penIcon from "../../../Assets/menuicon/pen.svg";
import starIcon from "../../../Assets/menuicon/staricon.svg";

export default function NewOrder() {
  // dropdown
  (function ($) {
    var CheckboxDropdown = function (el) {
      var _this = this;
      this.isOpen = false;
      this.areAllChecked = false;
      this.$el = $(el);
      this.$label = this.$el.find(".dropdown-label");
      this.$checkAll = this.$el.find('[data-toggle="check-all"]').first();
      this.$inputs = this.$el.find('[type="checkbox"]');

      this.onCheckBox();

      this.$label.on("click", function (e) {
        e.preventDefault();
        _this.toggleOpen();
      });

      this.$checkAll.on("click", function (e) {
        e.preventDefault();
        _this.onCheckAll();
      });

      this.$inputs.on("change", function (e) {
        _this.onCheckBox();
      });
    };

    CheckboxDropdown.prototype.onCheckBox = function () {
      this.updateStatus();
    };

    CheckboxDropdown.prototype.updateStatus = function () {
      var checked = this.$el.find(":checked");

      this.areAllChecked = false;
      this.$checkAll.html("Alle überprüfen");

      if (checked.length <= 0) {
        this.$label.html("Mitarbeiter zuordnen");
      } else if (checked.length === 1) {
        this.$label.html(checked.parent("label").text());
      } else if (checked.length === this.$inputs.length) {
        this.$label.html("Alle ausgewählt");
        this.areAllChecked = true;
        this.$checkAll.html("Alle deaktivieren");
      } else {
        this.$label.html(checked.length + "Ausgewählt");
      }
    };

    CheckboxDropdown.prototype.onCheckAll = function (checkAll) {
      if (!this.areAllChecked || checkAll) {
        this.areAllChecked = true;
        this.$checkAll.html("Alle deaktivieren");
        this.$inputs.prop("checked", true);
      } else {
        this.areAllChecked = false;
        this.$checkAll.html("Alle überprüfen");
        this.$inputs.prop("checked", false);
      }

      this.updateStatus();
    };

    CheckboxDropdown.prototype.toggleOpen = function (forceOpen) {
      var _this = this;

      if (!this.isOpen || forceOpen) {
        this.isOpen = true;
        this.$el.addClass("on");
        $(document).on("click", function (e) {
          if (!$(e.target).closest("[data-control]").length) {
            _this.toggleOpen();
          }
        });
      } else {
        this.isOpen = false;
        this.$el.removeClass("on");
        $(document).off("click");
      }
    };

    var checkboxesDropdowns = document.querySelectorAll(
      '[data-control="checkbox-dropdown"]'
    );
    for (var i = 0, length = checkboxesDropdowns.length; i < length; i++) {
      new CheckboxDropdown(checkboxesDropdowns[i]);
    }
  })(jQuery);
  // dropdown end
  const nevigate = useNavigate();
  const token = localStorage.getItem("token");
  const [customerdata, setCustomerdata] = useState([]);
  const [employeedata, setemployeeData] = useState([]);
  const [product, setProduct] = useState([]);
  const [ordertextarea, setOrdertextarea] = useState("");
  useEffect(() => {
    // customer api
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/user/all`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setCustomerdata(res.data.data);
      })
      .catch((err) => {
        console.log("errr");
      });

    // employee api
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/employee/get/all`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setemployeeData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [orderdata, setorderData] = useState([]);
  const initialValues = {
    ordername: "",
    products: [],
    orderpriority: "",
    files: [],
    orderdetail: "",
    employee: [],
  };

  const [orderfilechange, setorderFilechange] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const getRole = localStorage.getItem("role");
  const [imageUploadProgress, setImageUploadProgress] = useState(0);

  const [filename, SetFilename] = useState([]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: NeworderRegistrationSchema,
    onSubmit: (data) => {
      setIsLoading(true); // Start loading

      // progress bar
      const formData = new FormData();
      fileValue.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
      });

      let filenames = [];

      // Iterate through the orderfilechange array using forEach
      orderfilechange.forEach((file) => {
        console.log(file.fileName);

        // Assuming each item in orderfilechange is an object with a 'filename' property
        if (file.fileName) {
          // Push the fileName value into the filenames array
          filenames.push(file.fileName);
        }
      });

      // Now, the filenames array contains all the fileName values from objects with a 'filename' property
      console.log(filenames);

      axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/order/add`,
        data: {
          ...data,
          employee: emp,
          uId: ids,
          files: orderfilechange,
          filenames: orderfilechange,
        },
        headers: {
          "content-type": "multipart/form-data",
          authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setTimeout(() => {
            toast.success(res.data.message);
          }, 1000);
          if (getRole == 1) {
            nevigate("/admin/order");
          } else {
            nevigate("/employee/order");
          }
          setorderData(res.data.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false); // Stop loading, whether success or error
          setImageUploadProgress(0); // Reset the progress bar
        });
    },
  });

  function refreshPage() {
    window.location.reload(true);
  }

  // file add
  const [fileValue, setFileValue] = useState([]);
  const [getFiles, setGetFiles] = useState([]);

  const handleRemoveFile = (file) => {
    const updatedFiles = fileValue.filter((f) => f !== file);
    setFileValue(updatedFiles);
    const fileName = file.name;
    const foundFiles = [];

    orderfilechange.forEach((file) => {
      if (file.originalname === fileName) {
        foundFiles.push(file.fileName);
      }
    });
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/order/filechangedelete/`,
      headers: {
        authorization: `Bearer ${token}`,
      },
      data: { text: foundFiles },
    })
      .then((res) => {
        setorderFilechange(
          orderfilechange.filter((file) => file.originalname !== fileName)
        );

        // chatonchange1 = [];
      })
      .catch((res) => {
        // toast.error(res.response.message)
      })
      .finally(() => {
        // setIsLoading(false); // Stop loading, whether success or error
      });
  };

  const handleImageChange = (event, file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      file.preview = reader.result;
      setGetFiles([...getFiles]);
    };
    reader.readAsDataURL(file);
  };

  // selected employee
  const [emp, setEmp] = useState([]);
  const [ids, setID] = useState([]);
  const SelectedCompany = (id) => {
    setID(id);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/employee/get`,
      data: { id: id },
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setEmp(res.data.data);
      })
      .catch((res) => {
        toast.error(res.data.message);
      });
  };
  const onchangeinputorder = (files) => {
    // console.log(file);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/order/filechange/`,
      headers: {
        authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
      data: {
        files: files,
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setImageUploadProgress(progress);
      },
    })
      .then((res) => {
        // console.log(res.data.uploadedFileNames);
        setorderFilechange(res.data.uploadedFileNames);
      })
      .catch((err) => {})
      .finally(() => {});
    // You can also perform additional actions, such as uploading the files to a server.
    // Example: uploadFilesToServer(files);
  };
  const [genrate, setGenerate] = useState("");
  const [load, setLoad] = useState(false);
  const handleButtonClick = () => {
    // Make an API call to your backend with the input text
    // console.log(ordertextarea);
    setLoad(true);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/order/chatgpt/`,
      headers: {
        authorization: `Bearer ${token}`,
      },
      data: {
        text: ordertextarea,
      },
    })
      .then((res) => {
        // console.log(res.data.uploadedFileNames);
        // Inside your onSubmit or relevant function
setFieldValue('orderdetail',res.data.generatedText);

      
        setLoad(false);
        setGenerate(res.data.generatedText);
      })
      .catch((err) => {})
      .finally(() => {});
  };

  return (
    <>
      <div className="main-body" id="root1">
        <form
          action=""
          className="position-relative"
          onSubmit={handleSubmit}
          style={{ zIndex: "12" }}
        >
          <div className="order-header  neworder-header justify-content-between d-flex div">
            <div className="d-flex order-header-section">
              <img src={penIcon} className="pen_icon" alt="Pen Icon" />
              <h3 className="me-2 overflow-auto">{values.ordername}</h3>
            </div>
            <div className="d-flex">
              <button
                type="submit"
                onClick={handleSubmit}
                className="neworder-btn  w-100"
                disabled={isLoading}
              >
                {isLoading
                  ? "Wird bearbeitet..."
                  : // Replace with your loader icon
                    "Speichern"}
              </button>
            </div>
          </div>

          <div className="order-navigation-links  row">
            <div className="overview-page col-12 col-md-3 col-xxl-2">
              <i className="bi bi-chevron-left pe-2"></i>
              {getRole == 1 ? (
                <Link to="/admin/order" className="nav-link-first">
                  zurück zur Übersicht
                </Link>
              ) : (
                <Link to="/employee/order" className="nav-link-first">
                  zurück zur Übersicht
                </Link>
              )}
            </div>
            <div className="btn-customer ">
              <select
                type="text"
                name="uId"
                value={values.customer}
                placeholder="Kunde auswählen"
                className="order_input"
                // onChange={handleChange}
                onChange={(e) => SelectedCompany(e.target.value)}
                onBlur={handleBlur}
              >
                <i className="bi bi-list-ul"></i>
                <option value="" className="admin-select-customer">
                  Kunde auswählen
                </option>
                {customerdata.map((e) => {
                  return (
                    <option
                      value={e.id}
                      key={e.id}
                      className="admin-select-customer border border-1 border-danger"
                    >
                      {e.company}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="btn-employee  ">
              <div className="dropdown h-100" data-control="checkbox-dropdown">
                <label className="dropdown-label text-dark  admin-user-select order_input">
                  Mitarbeiter zuordnen
                </label>

                <div className="dropdown-list admin-select-employee-dropdown-list">
                  {employeedata.map((e, index) => {
                    return (
                      <label className="select-employee-label ">
                        <input
                          type="checkbox"
                          checked={emp && emp.includes(e.id)}
                          onChange={() => {
                            const selectedProducts = emp.slice();
                            const index = selectedProducts.indexOf(e.id);
                            if (index > -1) {
                              selectedProducts.splice(index, 1);
                            } else {
                              selectedProducts.push(e.id);
                            }
                            setEmp(selectedProducts);
                          }}
                          className="dropdown-group"
                          name="products"
                          value={e.id}
                          key={index.toString()}
                          onBlur={handleBlur}
                        />
                        {/* <input type="checkbox" className="dropdown-group  " name="employee" value={e.id} onChange={handleChange}
                        onBlur={handleBlur} /> */}
                        {e.fname + " " + e.lname}
                      </label>
                    );
                  })}
                </div>
              </div>
              {/* <div className="error">
  {errors.employee && touched.employee ? (
    <small className='form-error'>{errors.employee}</small>
  ) : null}
</div> */}
            </div>
            <div className=" ms-auto position-relative col-12 col-md-2">
              <div className="position-relative">
                <select
                  type="text"
                  name="orderstatus"
                  value={values.orderstatus}
                  className="nav-select w-100 form-select text-light"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Status ändern</option>
                  <option value="1">Neuer Auftrag</option>
                  <option value="2">Wird bearbeitet</option>
                  <option value="3">Abgeschlossen</option>
                </select>
                <i class="bi text-light bi-chevron-down my-auto order-select-icon"></i>
              </div>
              <div className="error">
                {/* {errors.status && touched.status ? (
    <small className='form-error'>{errors.status}</small>
  ) : null} */}
              </div>
            </div>
          </div>

          <div className="div order_box">
            <div className="description">
              <div className="row">
                <div className="col-lg-7 col-md-12">
                  <div className=" row">
                    <div className="col-lg-3 col-md-12   first_label d-flex ">
                      <label className="order-label ">
                        Titel: <small>*</small>
                      </label>
                    </div>
                    <div className="col-lg-9 col-md-12 d-flex first_input justify-content-center">
                      <input
                        type="text"
                        name="ordername"
                        value={values.ordername}
                        placeholder="Auftragsname..."
                        className="order_input"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className="error d-flex justify-content-center">
                    {errors.ordername && touched.ordername ? (
                      <small className="form-error">{errors.ordername}</small>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-5 mt-3 mt-lg-0 col-md-12">
                  <div className=" row">
                    <div className="col-lg-3 second_input col-md-12 d-flex justify-content-center">
                      <label className="order-label">
                        Priorität:<small>*</small>
                      </label>
                    </div>
                    <div className="col-lg-9 col-md-12 d-flex justify-content-center position-relative">
                      <select
                        className="form-select order_input user-select"
                        name="orderpriority"
                        value={values.orderpriority}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option className="user-option" value="">
                          Priorität auswählen
                        </option>
                        <option className="user-option" value="1">
                          Hoch
                        </option>
                        <option className="user-option" value="0">
                          Standard
                        </option>
                      </select>
                      <i class="bi bi-chevron-down my-auto order-select-icon"></i>
                    </div>
                  </div>
                  <div className="error d-flex justify-content-center">
                    {errors.orderpriority && touched.orderpriority ? (
                      <small className="form-error">
                        {errors.orderpriority}
                      </small>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row mt-3 border-border-info border-2">
                <div
                  className="col-lg-2 col-md-12 pe-0  d-flex"
                  style={{ marginRight: "-21px" }}
                >
                  <label className="order-label">
                    Dateien: <small>*</small>
                  </label>
                </div>
                <div className="col-lg-10 col-md-12 ps-0 pe-0">
                  <div className="file-area">
                    <input
                      type="file"
                      name="files[]"
                      multiple="multiple" 
                      className="order_file nowidth"
                      onChange={(event) => {
                        const files = Array.from(event.target.files);
                        setFileValue(files);

                        onchangeinputorder(files);
                        files.forEach((file) => {
                          handleImageChange(event, file);
                        });

                        const fileNames = Array.from(event.target.files).map(
                          (file) => {
                            return file.name;
                          }
                        );
                        SetFilename(fileNames);
                      }}
                    />
                    <div className="order_file  nowidth profile-input">
                      <div className="success">Dateien hochladen</div>
                    </div>
                    <div className="error">
                      {errors.files && touched.files ? (
                        <small className="form-error">{errors.files}</small>
                      ) : null}
                    </div>
                  </div>
                  <div className="img-previews">
                    {fileValue.map((file, index) => (
                      <div className="file-item" key={index}>
                        {file.type.startsWith("image/") ? (
                          <img
                            src={file.preview}
                            alt="Preview"
                            style={{ height: "100px", width: "100px" }}
                            title={file.name}
                          />
                        ) : (
                          <>
                            <div className="d-flex flex-column">
                              <i
                                class="bi bi-file-earmark-pdf-fill"
                                title={file.name}
                              ></i>
                            </div>
                          </>
                        )}
                        <div
                          className="cancle_icon"
                          onClick={() => handleRemoveFile(file)}
                        >
                          <i class="bi bi-x-circle-fill red_icon"></i>
                        </div>
                      </div>
                    ))}
                  </div>
                  {fileValue.length > 0 ? (
                    <div className="ps-2">
                      <progress value={imageUploadProgress} max="100" />
                      <span className="ps-2">{`${imageUploadProgress}%`}</span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="row mt-3">
                <div
                  className="col-lg-2 col-md-12  d-flex"
                  style={{ marginRight: "-21px" }}
                >
                  <label className="order-label">
                    Briefing: <small>*</small>
                  </label>
                </div>
                <div className="col-lg-10 col-md-12 first_input ps-0">
                  <textarea
                    className="order_textarea user-textarea"
                    placeholder="Mehr Informatiomem zu dem Neuer Auftrag..."
                    name="orderdetail"
                    value={values.orderdetail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                  <button
                    className="chatgpt_btn"
                    type="button"
                    onClick={handleButtonClick}
                  >
                    {load == false ? (
                      <>
                        <img
                          src={starIcon}
                          className="star_icon me-2"
                          alt="Star Icon"
                        />
                        Briefing mit KI umwandeln
                      </>
                    ) : (
                      <span class="loader1"></span>
                    )}
                  </button>
                  <div className="error mt-3">
                    {errors.orderdetail && touched.orderdetail ? (
                      <small className="form-error">{errors.orderdetail}</small>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}
