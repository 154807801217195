import {
  AddressElement,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
// import { confirmAlert } from "react-confirm-alert";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ProfileIcon from "../../Assets/menuicon/profile.svg";
// import styled, { createGlobalStyle } from "styled-components";

import "./Account.css";
import { AccountSchema } from "./AccountSchema";

export default function Account() {
  function membership(subs_status) {
    if (subs_status === 1) {
      return "Active";
    } else if (subs_status === 2) {
      return "Paused";
    }
  }
  // const nevigate = useNavigate()

  const [error] = useState(null);
  const [, setUpdate] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const token = localStorage.getItem("token");
  const [data, setData] = useState([]);
  const [link, setLink] = useState();

  const getuser = () => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/account/user/profile`,
      data: data,
      headers: {
        "content-type": "multipart/form-data",
        authorization: `Bearer ${token}`,
      },
    })
      .then(function (res) {
        setIsLoaded(true);
        setData(res.data.data);
        // console.log(res)
        // toast.success(res.data.message, { toastId: "unique-random-text-xAu9C9-" });
      })
      .catch(function (res) {
        setIsLoaded(true);
        // toast.error(res.response.data.message, { toastId: "unique-random-text-xAu9C9-" });
      });
  };
  useEffect(() => {
    getuser();
  }, []);

  const [values, setValues] = useState({
    email: data.email,
    password: "",
    company: data.company,
    street: data.street,
    postalNum: data.postalNum,
    country: data.country,
    fname: data.fname,
    lname: data.lname,
    file: data.userImg,
    repeatpassword: "",
    ustId: data.ustId,
    number: data.number,
    city: data.city,
    code: data.ustId,
    paymentStripe: data.paymentStripe,
  });
  // console.log(values);
  const initialErrors = {
    email: "",
    password: "",
    company: "",
    street: "",
    postalNum: "",
    country: "",
    fname: "",
    lname: "",
    file: "",
    repeatpassword: "",
    ustId: "",
    number: "",
    city: "",
    code: "",
    paymentStripe: "",
  };
  const [errors, setErrors] = useState(initialErrors);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const validatePassword = (password, repeatpassword) => {
    return password === repeatpassword;
  };

  const [errors1, setError] = useState({
    email: "",
    password: "",
    repeatpassword: "",
    company: "",
    street: "",
    postalNum: "",
    country: "",
    fname: "",
    file: "",
    lname: "",
    ustId: "",
    number: "",
    city: "",
    code: "",
    paymentStripe: "",
  });
  const handleChange = (event) => {
    var newdata = data;
    const { name, value } = event.target;

    setData({ ...data, [name]: value });
    // console.log(data);
    if (name === "email") {
      if (!validateEmail(value)) {
        setError({ ...errors1, email: "Ungültig E-Mail..." });
      } else {
        setError({ ...errors1, email: "" });
      }
    } else {
      setError({ ...errors1, [name]: "" });
    }
  };
  const handleBlur = (event) => {
    const { name, value } = event.target;
    var newdata = data;
    // console.log(newdata.password);
    // console.log(value);
    if (name == "password" || name == "repeatpassword") {
      // console.log(value.length);
      if (value.length < 6) {
        setError({
          ...errors1,
          [name]: "Das Passwort darf maximal 6 Zeichen lang sein",
        });
      } else if (value.length > 20) {
        setError({
          ...errors1,
          [name]: "Das Passwort darf maximal 20 Zeichen lang sein",
        });
      } else {
        setError({ ...errors1, [name]: "" });
      }
    }

    setTimeout(() => {
      // console.log("hiii")
      if (
        newdata.password != undefined &&
        newdata.repeatpassword != undefined
      ) {
        // console.log('asdasd');
        if (!validatePassword(newdata.password, newdata.repeatpassword)) {
          setError({
            ...errors1,
            repeatpassword: "Passwort müss übereinstimmen",
          });
        } else {
          setError({ ...errors1, [name]: "" });
        }
      }
    }, 1000);
  };

  // console.log(errors1);

  const [isLoading, setIsLoading] = useState(false);

  const { touched, setFieldValue } = (event) => {};

  const handleSubmit = (event) => {
    // const [data, setData] = useState([]);
    var newdata = data;

    let hasEmptyFields = false;
    const errorMessages = {};
    for (const key in newdata) {
      // console.log('ASdas');
      if (
        newdata[key] === "" &&
        key != "active_membership_status" &&
        key != "role" &&
        key != "password" &&
        key != "repeatpassword" &&
        key != "ustId"
      ) {
        if (key === "company") {
          errorMessages.company = "Unternehmen...";
        } else if (key === "street") {
          errorMessages.street = "Straße...";
        } else if (key === "postalNum") {
          errorMessages.postalNum = "Postleitzahl...";
        } else if (key === "country") {
          errorMessages.country = "Land...";
        } else if (key === "number") {
          errorMessages.number = "Nummer...";
        } else if (key === "city") {
          errorMessages.city = "Stadt...";
        } else if (key === "fname") {
          errorMessages.fname = "Vorname...";
        } else if (key === "lname") {
          errorMessages.lname = "Nachname...";
        } else if (key === "email") {
          errorMessages.email = "E-Mail...";
        }
        hasEmptyFields = true;
      }
    }
    setError({ ...errors1, ...errorMessages });

    event.preventDefault();
    setIsLoading(true);

    setTimeout(() => {
      // console.log(validatePassword);
      if (data.password == undefined) {
        // console.log("sadd");
        newdata.password = "";
      }
      if (data.repeatpassword == undefined) {
        newdata.repeatpassword = "";
      }
      // console.log(newdata);

      if (hasEmptyFields) {
        setIsLoading(false);
        // console.log("Some fields are empty.");
        // You can display an error message or take appropriate action here
      } else {
        axios({
          method: "PATCH",
          url: `${process.env.REACT_APP_BASE_URL}/account/user/profile`,
          data: newdata,
          headers: {
            "content-type": "multipart/form-data",
            authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            setIsLoading(false);
            // console.log(res);
            toast.success(res.data.message, {
              toastId: "unique-random-text-xAu9C9-",
            });
            // Handle the response as needed
          })
          .catch((res) => {
            // setIsLoading(false);
            // console.log(error);
            toast.error(res.response.data.message);
            // Handle the error response as needed
          })
          .finally(() => {
            setIsLoading(false); // Stop loading, whether success or error
          });
      }
    }, 1000);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <img
        src={"https://i.gifer.com/VAyR.gif"}
        className="loader"
        alt="loading"
      />
    );
  } else {
    return (
      <>
        <div className="main-body dashboard" id="root1">
          <form className="position-relative" onSubmit={handleSubmit} style={{zIndex:'12'}}>
            <div className="myaccount-header d-flex div">
              <img
                src={ProfileIcon}
                className="my-auto"
                width={50}
                height={40}
              />
              <h3 className="ms-3">Konto </h3>
              <button
                type="submit"
                onClick={handleSubmit}
                className="order-btn"
                disabled={isLoading}
              >
                {isLoading
                  ? " Wird bearbeitet..."
                  : // Replace with your loader icon
                    "Speichern"}
              </button>
            </div>

            <div className="div ">
              <div className="description row">
                <div className="row  Registration ">
                  <div className="col-md-6 ">
                    <div className="field row">
                      <div className="col-lg-3 col-md-12 d-flex">
                        <label className="customer-label">
                          Vorname: <small>*</small>
                        </label>
                      </div>
                      <div className="col-md-12 col-lg-9">
                        <input
                          type="text"
                          name="fname"
                          defaultValue={data.fname}
                          placeholder="Vorname..."
                          className="customer-input"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="error">
                        {errors1.fname ? (
                          <small className="form-error">{errors1.fname}</small>
                        ) : null}
                      </div>
                    </div>

                    <div className="field row mt-3">
                      <div className="col-md-12 col-lg-3 d-flex">
                        <label className="customer-label">
                          E-Mail: <small>*</small>
                        </label>
                      </div>
                      <div className="col-md-12 col-lg-9">
                        <input
                          type="email"
                          name="email"
                          value={data.email}
                          placeholder="E-Mail..."
                          className="customer-input"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>

                      <div className="error">
                        {errors1.email ? (
                          <small className="form-error">{errors1.email}</small>
                        ) : null}
                      </div>
                    </div>
                    <div className="field row mt-3">
                      <div className="col-md-12 col-lg-3 d-flex">
                        <label className="customer-label">
                          Passwort: <small></small>
                        </label>
                      </div>
                      <div className="col-md-12 col-lg-9">
                        <input
                          type="password"
                          name="password"
                          defaultValue={data.password}
                          placeholder="Passwort..."
                          className="customer-input"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>

                      <div className="error">
                        {errors1.password ? (
                          <small className="form-error">
                            {errors1.password}
                          </small>
                        ) : null}
                      </div>
                    </div>

                    <div className="field row mt-3">
                      <div className="col-md-12 col-lg-3 d-flex">
                        <label className="customer-label">
                          Unternehmen: <small>*</small>
                        </label>
                      </div>
                      <div className="col-md-12 col-lg-9">
                        <input
                          type="text"
                          name="company"
                          defaultValue={data.company}
                          placeholder="Unternehmen..."
                          className="customer-input"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="error">
                        {" "}
                        {errors1.company ? (
                          <small className="form-error">
                            {errors1.company}
                          </small>
                        ) : null}
                      </div>
                    </div>

                    <div className="field row mt-3">
                      <div className="col-md-12 col-lg-3 d-flex">
                        <label className="customer-label">
                          Straße: <small>*</small>
                        </label>
                      </div>
                      <div className="col-md-12 col-lg-9">
                        <input
                          type="text"
                          name="street"
                          defaultValue={data.street}
                          placeholder="Straße...."
                          className="customer-input"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="error">
                        {errors1.street ? (
                          <small className="form-error">{errors1.street}</small>
                        ) : null}
                      </div>
                    </div>

                    <div className="field row mt-3">
                      <div className="col-md-12 col-lg-3 d-flex">
                        <label className="customer-label">
                          Postleitzahl: <small>*</small>
                        </label>
                      </div>
                      <div className="col-md-12 col-lg-9">
                        <input
                          type="text"
                          name="postalNum"
                          defaultValue={data.postalNum}
                          placeholder="Postleitzahl..."
                          className="customer-input"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="error">
                        {errors1.postalNum ? (
                          <small className="form-error">
                            {errors1.postalNum}
                          </small>
                        ) : null}
                      </div>
                    </div>

                    <div className="field row mt-3">
                      <div className="col-md-12 col-lg-3 d-flex">
                        <label className="customer-label">
                          Land: <small>*</small>
                        </label>
                      </div>
                      <div className="col-md-12 col-lg-9">
                        <input
                          type="text"
                          name="country"
                          defaultValue={data.country}
                          placeholder="Land..."
                          className="customer-input"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>

                      <div className="error">
                        {errors1.country ? (
                          <small className="form-error">
                            {errors1.country}
                          </small>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="field row">
                      <div className="col-md-12 col-lg-3 d-flex">
                        <label className="customer-label">
                          Nachname: <small>*</small>
                        </label>
                      </div>
                      <div className="col-md-12 col-lg-9">
                        <input
                          type="text"
                          name="lname"
                          defaultValue={data.lname}
                          placeholder="Nachname..."
                          className="customer-input"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>

                      <div className="error">
                        {errors1.lname ? (
                          <small className="form-error">{errors1.lname}</small>
                        ) : null}
                      </div>
                    </div>
                    <div className="field row mt-3">
                      <div className="col-md-12 col-lg-3 d-flex">
                        <label className="customer-label">
                          Profilbild: <small></small>
                        </label>
                      </div>
                      <div className="col-md-12 col-lg-9">
                        <div className="row">
                          <div className="col-8 col-md-10">
                            <div className="file-area">
                              <input
                                type="file"
                                name="file"
                                multiple="multiple"
                                placeholder="Write your E-Mail."
                                className="customer-input"
                                onChange={(event) => {
                                  const selectedFile = event.target.files[0];

                                  // You can add validation here, e.g., checking file type, size, etc.
                                  // If validation fails, you can set an error message in the 'errors1' state.

                                  setData({ ...data, file: selectedFile });
                                }}
                              />
                              <div className="customer-input profile-input">
                                <div className="success">
                                  Profilbild
                                  hochladen
                                </div>
                              </div>
                              <div className="error">
                                {errors1.file ? (
                                  <small className="form-error">
                                    {errors1.file}
                                  </small>
                                ) : null}
                              </div>
                            </div>
                          </div>

                          <div className="col-1 col-md-2 profile-img">
                            {data.file ? (
                              <img
                                src={`${process.env.REACT_APP_IMG_URL}/assets/profilepic/${data.file}`}
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  borderRadius: "50%",
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  borderRadius: "50%",
                                  background: "grey",
                                }}
                              ></div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="field row mt-3">
                      <div className=" col-md-12 col-lg-3 d-flex">
                        <label className="customer-label">
                          Passwort wiederholen: <small></small>
                        </label>
                      </div>
                      <div className="col-md-12 col-lg-9">
                        <input
                          type="password"
                          name="repeatpassword"
                          placeholder="Passwort wiederholen..."
                          className="customer-input"
                          defaultValue={data.repeatpassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="error">
                        {errors1.repeatpassword ? (
                          <small className="form-error">
                            {errors1.repeatpassword}
                          </small>
                        ) : null}
                      </div>
                    </div>

                    <div className="field row mt-3">
                      <div className="col-md-12 col-lg-3 d-flex">
                        <label className="customer-label">
                          USt-IdNr.: <small></small>
                        </label>
                      </div>
                      <div className="col-md-12 col-lg-9">
                        <input
                          type="text"
                          name="ustId"
                          placeholder="USt-IdNr..."
                          className="customer-input"
                          defaultValue={data.ustId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="error">
                        {errors1.ustId ? (
                          <small className="form-error">{errors1.ustId}</small>
                        ) : null}
                      </div>
                    </div>

                    <div className="field row mt-3">
                      <div className="col-md-12 col-lg-3 d-flex">
                      <label className="customer-label">
                        Nummer: <small>*</small>
                      </label>
                      </div>
                      <div className="col-md-12 col-lg-9">
                      <input
                        type="text"
                        name="number"
                        placeholder="Nummer..."
                        className="customer-input"
                        defaultValue={data.number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      </div>
                     
                    

                      <div className="error">
                        {errors1.number ? (
                          <small className="form-error">{errors1.number}</small>
                        ) : null}
                      </div>
                    </div>

                    <div className="field row mt-3">
                      <div className="col-md-12 col-lg-3 d-flex">
                        <label className="customer-label">
                          Stadt: <small>*</small>
                        </label>
                      </div>
                      <div className="col-md-12 col-lg-9">
                        <input
                          type="text"
                          name="city"
                          placeholder="Stadt..."
                          className="customer-input"
                          defaultValue={data.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>

                      <div className="error">
                        {errors1.city ? (
                          <small className="form-error">{errors1.city}</small>
                        ) : null}
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </>
    );
  }
}
