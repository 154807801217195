import React, { useEffect, useState } from "react";
import "./addcourse.css";
import bulb from "../../../Assets/menuicon/Group.png";
import { useFormik } from "formik";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useLocation, useParams } from "react-router-dom";

const UpdateCourse = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem("token");
    const queryParams = new URLSearchParams(location.search);

    const { course_id } = useParams();

    const [values, setValues] = useState({ coursename: "", coursedesc: "" });
    const [updatedValues, setUpdatedValues] = useState({ coursename: "", coursedesc: "" });

    const getCourseById = () => {
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_BASE_URL}/get/course/byid/${course_id}`,
            headers: {
                authorization: `Bearer ${token}`,
            },
        })
        .then((res) => {
            const courseName = res.data.data.map((item) => item.course_name);
            const courseDesc = res.data.data.map((item) => item.course_description);
            setValues({ coursename: courseName, coursedesc: courseDesc });
        })
        .catch((res) => {
            toast.error(res.response.message);
        });
    };

    useEffect(() => {
        getCourseById();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUpdatedValues({ ...updatedValues, [name]: value });
    };

    const formik = useFormik({
        initialValues: values,
        onSubmit: (values) => {
            const dataToSend = {
                coursename: updatedValues.coursename || values.coursename,
                coursedesc: updatedValues.coursedesc || values.coursedesc,
            };
            // console.log(dataToSend);

            axios({
                method: "POST",
                url: `${process.env.REACT_APP_BASE_URL}/update/course/${course_id}`,
                data: dataToSend,
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                // console.log(res.data);
                if (res.data.message === "Course updated successfully") {
                    toast.success(res.data.message);
                    navigate("/admin/course");
                } else {
                    toast.error("Failed to update course");
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
        }
    });

    return (
        <>
            <div className="main-body" id="root1">
                <form onSubmit={formik.handleSubmit} className="add-course-form">
                    <div className="order-header neworder-header justify-content-between d-flex div">
                        <div className="d-flex order-header-section">
                            <img src={bulb} width={35} height={45} alt="Pen Icon" />
                            <h3 className="me-2">Kurs aktualisieren</h3>
                        </div>
                        <div className="d-flex">
                            <button type="submit" className="neworder-btn ">Speichern</button>
                        </div>
                    </div>

                    <div className="div order_box">
                        <div className="description">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-12 first_label d-flex ">
                                            <label className="order-label ">Course Name: <small>*</small></label>
                                        </div>
                                        <div className="col-lg-9 col-md-12 d-flex first_input justify-content-center">
                                            <input
                                                type="text"
                                                name="coursename"
                                                value={updatedValues.coursename || values.coursename}
                                                placeholder="Course Name"
                                                className="order_input"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-lg-3 col-md-12 first_label d-flex ">
                                            <label className="order-label ">Course Description: <small>*</small></label>
                                        </div>
                                        <div className="col-lg-9 col-md-12 d-flex first_input justify-content-center">
                                            <input
                                                type="text"
                                                name="coursedesc"
                                                value={updatedValues.coursedesc || values.coursedesc}
                                                placeholder="Course Description"
                                                className="order_input"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </>
    )
}

export default UpdateCourse;
