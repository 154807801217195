
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FaUserAlt } from "react-icons/fa";
import { BsBinocularsFill } from "react-icons/bs";
import { GiPartyPopper } from "react-icons/gi";
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { BiLogOutCircle, BiPen } from "react-icons/bi";
import dashboardIcon from '../Assets/menuicon/Vector.png';
import penIcon from '../Assets/menuicon/Clip path group.png';
import eventIcon from '../Assets/menuicon/event.png';
import profileIcon from '../Assets/menuicon/person.png';
import supportIcon from '../Assets/menuicon/support.png';


import "./Userstyle/Layout.css";
// import image from '../Assets/images/logo.png'
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
const Usermenu = ({ children }) => {
  const navigate = useNavigate();

  const logoutuser = () => {
    localStorage.clear("notes");
    navigate("/")
  }
  const token = localStorage.getItem('token')
  const email = localStorage.getItem('email')
  // nosubscribe product send to product page
  const NoSubsproduct = () => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/subscribe/products`,
      data: { email: email },
      headers: {
        'authorization': `Bearer ${token}`
      },
    }).then((res) => {
      if (res.data.expire == true) {
        toast.success(res.data.message, { toastId: "unique-random-text-xAu9C9-" })
        navigate("/products")
      } else {
        navigate("/user/neworder")
      }

    }).catch((res) => {
      toast.error(res.response.data.message)
    })
  }
  const menuItem = [
    {
      path: "dashboard",
      name: "Dashboard",
      icon: <img src={dashboardIcon} width={27} height={22} alt="Dashboard" />,
    },

    {
      path: "neworder",
      name: "Neuer Auftrag",
      icon: <img src={penIcon} width={32} height={32} alt="new_order" />,
      // onClick: NoSubsproduct,
    },

    {
      path: "events",
      name: "Events",
      icon: <img src={eventIcon} width={25} height={25} alt="event" />,
    },

  ];
  const Endmenu = [
    {
      path: "account",
      name: "Mein Konto",
      icon: <img src={profileIcon} width={25} height={25} alt="account" />,
    },
    {
      path: "support",
      name: "Support",
      icon:<img src={supportIcon} width={25} height={25} alt="support" />,
    },

  ]
  const logout = [
    {
      path: "/",
      name: "Abmelden",
      icon:<BiLogOutCircle />,
    }
  ]

  return (
    <>
      <div className="admin-container containerr">
        <div className="sidebar">
          <div className="top-section">
            <div className="logo">
              <img src='https://app.7i7.de/static/media/logo.e4d8a2f52f85ba3558fc.png' style={{ width: "50%" }} alt="logo" className="logo_img" />
            </div>
          </div>

          <div className="up">
            {menuItem.map((item, index) => (
              <NavLink
                to={item.path}
                key={index}
                className="link"
                activeClassName="active"
                onClick={item.onClick} // Attach the onClick event to the item
              >
                <div className="user_menu_1"><div className="icon">{item.icon}</div>
                <div className="link-text">{item.name}</div></div>
              </NavLink>
            ))}
          </div>

          <div className="admin-End">
            {Endmenu.map((item, index) => (
              <NavLink
                to={item.path}
                key={index}
                className="link "
                activeClassName="active"
              >
                <div className="icon">{item.icon}</div>
                <div className="link-text"> {item.name}</div>
              </NavLink>
            ))}
            {logout.map((item, index) => (
              <NavLink
                to={item.path}
                key={index}
                className="link "
                activeClassName="active"
                onClick={logoutuser}
              >
                <div className="icon">{item.icon}</div>
                <div className="link-text"> {item.name}</div>
              </NavLink>
            ))}
            <p className="text-center"> </p>
          </div>
        </div>
      </div>


      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};
export default Usermenu;
