import axios from "axios";
class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
    this.url = `${process.env.REACT_APP_BASE_URL}/email-template-attachement`;
  }

  request(file) {
    return fetch(this.url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: file,
    });
  }
  upload() {
    const formData = new FormData();

    return this.loader.file
      .then((file) => {
        formData.append("upload", file, file.name);

        return this.request(formData)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Upload failed");
            }
            // Assuming the server responds with JSON data containing the URL of the uploaded file
            return response
              .json()
              .then((data) => {
                const imageUrl = data.url; // Assuming 'url' is the key containing the image URL
                return { default: imageUrl };
              })
              .catch((error) => {
                console.error("Error parsing response:", error);
                throw error;
              });
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
            throw error;
          });
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
        throw error;
      });
  }
}

export default function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}
