import React, { useEffect, useState, useRef } from "react";
import "./lessons.css";
import "./table.css";
import bulb from "../../../../Assets/menuicon/Group.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";

import styled from "styled-components";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import aerro from "../../../../Assets/menuicon/Vector (7).png";
import Select from "react-select";
import { useFormik } from "formik";
import AddCourseSchema from "../AddCourseSchema";
import AddLessonsSchema from "./AddLessonsSchema";
import eye from "../../../../Assets/images/Vector (6).png";
import DraggableTable from "./table";
const StyledCell = styled.div`
  &.Standard {
    border-radius: 755.494px;
    background: rgba(255, 255, 255, 0.2);
    font-family: Poppins;
    width: 120px;
    font-size: 16px;
    padding: 3px 0px;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
  &.Hoch {
    border-radius: 755.494px;
    background: #fff;
    text-align: center;
    color: #000;
    padding: 3px 0px;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 120px;
  }
  &.Neuer {
    border-radius: 75px;
    background: rgba(255, 255, 255, 0.2);
    width: 204px;
    height: 31px;
    padding: 3px;
    font-size: 16px;
    white-space: nowrap;

    height: 31px;
    text-align: center;
  }
  &.Wird {
    border-radius: 75px;
    width: 204px;
    background: linear-gradient(
      92deg,
      rgba(192, 222, 96, 0.2) 0.85%,
      rgba(96, 213, 222, 0.2) 110.38%
    );
    padding: 3px 6px;
    font-size: 16px;
    white-space: nowrap;

    text-align: center;
  }
  &.Abgeschlossen {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 75px;
    width: 204px;
    padding: 3px 39px;
    font-size: 16px;
    height: 31px;
    text-align: center;
    white-space: nowrap;
  }
`;

const Lessons = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const courseName = queryParams.get("course");
  const courseId = queryParams.get("course_id");
  const token = localStorage.getItem("token");
  const getRole = localStorage.getItem("role");
  const [isLoading, setIsLoading] = useState(false);

  // add lesson code
  const [fileValue, setFileValue] = useState([]);
  const [EditfileValue, setEditFileValue] = useState([]);

  const [orderfilechange, setorderFilechange] = useState([]);
  const [ordereditfilechange, setorderEditFilechange] = useState([]);

  const [imageUploadProgress, setImageUploadProgress] = useState(0);
  const [imageeditUploadProgress, setImageEditUploadProgress] = useState(0);
  const [values1, setValues1] = useState([]);
  const [getFiles, setGetFiles] = useState([]);
  const [getEditFiles, setEditGetFiles] = useState([]);

  const [filename, SetFilename] = useState([]);
  const [lessonName, setLessonName] = useState("");
  const initialValues = {
    courseid: courseId,
    lessonname: "",
    lessondesc: "",
    topics: "",
    files: [],
  };
  console.log(values1);
  const handleEditChange = (event) => {
    // Update the lessonName state with the new input value
    setLessonName(event.target.value);
  };
  const getCourseById = () => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/get/course/byid/${courseId}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        // console.log(res.data.data[0]);
        setValues1(res.data.data[0]);
      })
      .catch((res) => {
        toast.error(res.response.message);
      });
  };

  useEffect(() => {}, []);
  const { values, errors, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: AddLessonsSchema,
    onSubmit: (data) => {
      // console.log(data);
      const formData = new FormData();
      fileValue.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
      });

      let filenames = [];

      // Iterate through the orderfilechange array using forEach
      orderfilechange.forEach((file) => {
        // Assuming each item in orderfilechange is an object with a 'filename' property
        if (file.fileName) {
          // Push the fileName value into the filenames array
          filenames.push(file.fileName);
        }
      });

      // console.log(filenames);

      axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/lesson/add`,
        data: {
          ...data,
          files: orderfilechange,
          filenames: orderfilechange,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setTimeout(() => {
            toast.success(res.data.message);
          }, 1000);
          resetForm();
          navigate(
            `/admin/course/lessons?course=${courseName}&&course_id=${courseId}`
          );
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(error);
        })
        .finally(() => {
          // setIsLoading(false); // Stop loading, whether success or error
          setImageUploadProgress(0); // Reset the progress bar
        });
    },
  });

  const handleImageChange = (event, file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      file.preview = reader.result;
      setGetFiles([...getFiles]);
    };
    reader.readAsDataURL(file);
  };
  const handleEditImageChange = (event, file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      file.preview = reader.result;
      setEditGetFiles([...getEditFiles]);
    };
    reader.readAsDataURL(file);
  };
  const handleRemoveFile = (file) => {
    const updatedFiles = fileValue.filter((f) => f !== file);
    setFileValue(updatedFiles);
    const fileName = file.name;
    const foundFiles = [];

    orderfilechange.forEach((file) => {
      if (file.originalname === fileName) {
        foundFiles.push(file.fileName);
      }
    });
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/lesson/filechangedelete/`,
      headers: {
        authorization: `Bearer ${token}`,
      },
      data: { text: foundFiles },
    })
      .then((res) => {
        setorderFilechange(
          orderfilechange.filter((file) => file.originalname !== fileName)
        );

        // chatonchange1 = [];
      })
      .catch((res) => {
        // toast.error(res.response.message)
      })
      .finally(() => {
        // setIsLoading(false); // Stop loading, whether success or error
      });
  };
  const [oldfile, setOldfile] = useState("");
  const [filereal, setfileReal] = useState("");

  const handleEditRemoveFile = (originalfilename, filename) => {
    setOldfile(originalfilename);
    setfileReal(filename);
  };

  const onchangeinputorder = (files) => {
    // console.log(files);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/lesson/filechange/`,
      headers: {
        authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
      data: {
        files: files,
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setImageUploadProgress(progress);
      },
    })
      .then((res) => {
        // console.log(res.data.uploadedFileNames);
        setorderFilechange(res.data.uploadedFileNames);
      })
      .catch((err) => {})
      .finally(() => {});
    // You can also perform additional actions, such as uploading the files to a server.
    // Example: uploadFilesToServer(files);
  };
  const handleEditSubmit = async (
    id,
    lessonname,
    originalfilename,
    file_name
  ) => {
    // console.log('sadsadsad');
    setIsLoading(true); // Start loading
    console.log(lessonName);
    console.log(ordereditfilechange);
    console.log(oldfile);
    console.log(filereal);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/course/update/${id}`,
        {
          lessonName: lessonName == "" ? lessonname : lessonName,
          files: ordereditfilechange,
          filenames: ordereditfilechange,
          removefile: filereal,
          removeorginal: oldfile,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      setIsLoading(false);
      console.log(response.data);

      // resetForm();
      // navigate(`/admin/course/lessons?course=${courseName}&&course_id=${courseId}`);
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error);
    } finally {
      setImageEditUploadProgress(0);
      setIsLoading(false);
      setInputshow(0);
      fetchLessonsByCourseId();
      setOldfile("");
      setfileReal("");
      setEditGetFiles([]);
      setorderEditFilechange([]);
      setLessonName("");
      setEditFileValue([]);
    }
    // setIsLoading(false); // Start loading
  };
  const handlecancel = () => {
    setImageEditUploadProgress(0);
    setIsLoading(false);
    setInputshow(0);
    fetchLessonsByCourseId();
    setOldfile("");
    setfileReal("");
    setEditGetFiles([]);
    setorderEditFilechange([]);
    setLessonName("");
    setEditFileValue([]);
  };
  const onchangeinputorder1 = (files) => {
    // console.log(files);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/lesson/filechange/`,
      headers: {
        authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
      data: {
        files: files,
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setImageEditUploadProgress(progress);
      },
    })
      .then((res) => {
        // console.log(res.data.uploadedFileNames);
        setorderEditFilechange(res.data.uploadedFileNames);
      })
      .catch((err) => {})
      .finally(() => {});
    // You can also perform additional actions, such as uploading the files to a server.
    // Example: uploadFilesToServer(files);
  };
  // add lesson code end

  // get selected emp
  const selectedemp = () => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/getselected/allemp`,
      headers: {
        authorization: `Bearer ${token}`,
      },
      data: {
        courseId: courseId,
      },
    })
      .then((res) => {
        const empIds = res.data.data.map((item) => item.emp);
        const empIdsArray = empIds
          .join(",")
          .split(",")
          .map((id) => parseInt(id.trim()));
        // console.log(empIdsArray);
        setSelectedEmpData(empIdsArray);
      })
      .catch((res) => {
        // setIsLoaded(true);
        toast.error(res.response.message);
      });
  };
  useEffect(() => {
    selectedemp();
  }, []);
  // get selected emp end

  // emp add in course
  const [empdata, setEmpdata] = useState([]);
  const [selectedEmpData, setSelectedEmpData] = useState([]);

  const handleEmpChange = (selectedOptions) => {
    const empValuesArray = selectedOptions.map((option) => option.value);
    const empValuesString = empValuesArray.join(",");
    setSelectedEmpData(selectedOptions.map((option) => option.value));
    // console.log(empValuesString);

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/add/emp`,
      data: {
        courseId: courseId,
        empString: empValuesString,
      },
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  // emp add in course end

  const navigate = useNavigate();

  const click = (lesson_id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Möchten Sie diesen Datensatz löschen?",
      buttons: [
        {
          label: "Ja",
          onClick: () => deletelesson(lesson_id),
        },
        {
          label: "Nein",
        },
      ],
    });
  };

  const deletelesson = (lesson_id) => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/lesson/remove/${lesson_id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        // console.log(res.data);
        toast.success(res.data.message, {
          toastId: "unique-random-text-xAu9C9-",
        });
        fetchLessonsByCourseId();
      })
      .catch((res) => {
        toast.error(res.response.data.message);
      });
  };

  const [inputshow, setInputshow] = useState(0);
  const updatelesson = (lesson_id) => {
    console.log(lesson_id);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/lesson/get/lessonid`,
      headers: {
        authorization: `Bearer ${token}`,
      },
      data: {
        lessonId: lesson_id,
      },
    })
      .then((res) => {
        console.log(res.data);
        setInputshow(lesson_id);
        // const lessonId = res.data.data.map((item) => item.lesson_id);
        // const lessonName = res.data.data.map((item) => item.lesson_name);
        // const fileName = res.data.data.map((item) => item.file_name);
        // setValues({ lessonid: lessonId[0], lessonname: lessonName[0], fileName: fileName[0] });
        // if (fileName[0]) {
        //     // Set file value for preview
        //     setFileValue([{ name: fileName[0], preview: `${process.env.REACT_APP_BASE_URL}/${fileName[0]}` }]);
        // }
      })
      .catch((error) => {
        // Handle error (e.g., show toast with error message)
      });
  };
  const handledownword = (index) => {
    console.log(index);
    console.log(sortedData);
  };
  const handleupword = (index) => {
    console.log(index);
  };
  const customCell = (row, func2) => (
    <>
      {inputshow == row.lesson_id ? (
        <div className="d-flex lessons gap-2">
          <button
            type="button"
            onClick={() => {
              handleEditSubmit(
                row.lesson_id,
                row.lesson_name,
                row.file_name,
                row.file_originalname
              );
            }}
            className="order-btn  w-100"
            disabled={isLoading}
          >
            {/* sdsadsadas */}
            {isLoading
              ? "Wird bearbeitet..."
              : // Replace with your loader icon
                "Speichern"}
          </button>
          <button
            type="button"
            onClick={() => {
              handlecancel();
            }}
            className=" cancel_btn w-100"
            disabled={isLoading}
          >
            {/* sdsadsadas */}
            Cancel
          </button>
        </div>
      ) : (
        <div className="d-flex">
          {getRole === "1" ? (
            <div className="me-3 my-auto ">
              {/* <Link> */}
              <svg
                className="my-auto d-flex"
                onClick={() => updatelesson(row.lesson_id)}
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  id="Vector"
                  d="M0 14.2505V18H3.74949L14.808 6.9415L11.0585 3.19201L0 14.2505ZM17.7076 4.0419C17.8003 3.94939 17.8738 3.83952 17.924 3.71856C17.9742 3.59761 18 3.46794 18 3.33699C18 3.20604 17.9742 3.07638 17.924 2.95542C17.8738 2.83446 17.8003 2.72459 17.7076 2.63209L15.3679 0.292404C15.2754 0.199713 15.1655 0.126176 15.0446 0.0760011C14.9236 0.0258265 14.794 0 14.663 0C14.5321 0 14.4024 0.0258265 14.2814 0.0760011C14.1605 0.126176 14.0506 0.199713 13.9581 0.292404L12.1284 2.12216L15.8778 5.87165L17.7076 4.0419Z"
                  fill={`${
                    row.orderstatus == 3 ? "rgb(120,120,120)" : "#BCBCBC"
                  }`}
                />
              </svg>
              {/* </Link> */}
            </div>
          ) : null}

          {getRole === "1" ? (
            <div className="ms-2 my-auto">
              <svg
                className="my-auto d-flex"
                onClick={() => func2(row.lesson_id)}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Frame">
                  <path
                    id="Vector"
                    d="M5.99878 19C5.99878 20.1 6.89878 21 7.99878 21H15.9988C17.0988 21 17.9988 20.1 17.9988 19V7H5.99878V19ZM18.9988 4H15.4988L14.4988 3H9.49878L8.49878 4H4.99878V6H18.9988V4Z"
                    fill={`${
                      row.orderstatus == 3 ? "rgb(120,120,120)" : "#BCBCBC"
                    }`}
                  />
                </g>
              </svg>
            </div>
          ) : (
            <div className="me-3 my-auto">
              <Link
                to={`singlelesson?lesson=${row.lesson_name}&&lesson_id=${row.lesson_id}&&course_id=${row.course_id}`}
                className="d-flex align-items-center"
              >
                <img src={eye} height="18" width="25" />
              </Link>
            </div>
          )}
        </div>
      )}
    </>
  );

  // get emp
  useEffect(() => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/emp/get`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        const options = res.data.data.map((item) => ({
          value: item.id,
          label: item.fname,
        }));
        setEmpdata(options);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [data, setData] = useState([]);

  const fetchLessonsByCourseId = () => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/lesson/get/by-course`,
      headers: {
        authorization: `Bearer ${token}`,
      },
      data: {
        courseId: courseId,
      },
    })
      .then((res) => {
        setData(res.data.data);
        console.log(res.data);
        // Handle success (e.g., show toast)
      })
      .catch((error) => {
        // Handle error (e.g., show toast with error message)
      });
  };

  useEffect(() => {
    fetchLessonsByCourseId();
    setIsLoading(false);
    getCourseById();
  }, [courseId]);
  const columns1 = [
    { field: "lesson_name", header: "" },
    // { field: "file_originalname", header: "" },
  ];
  const column = [
    {
      name: "",
      width: "4%",
      selector: (row, index) => (
        <>
          {getRole == 1 ? (
            <div
              key={index}
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDragOver={(e) => handleDragOver(e, index)}
              // onDrop={(e) => handleDrop(e, index)}
              style={{ cursor: "move" }}
              // onDragEnd={(e)=>handleDrop(e,index)}
            >
              <i class="bi bi-grip-vertical"></i>
            </div>
          ) : (
            <div
              // key={index}
              // onDragEnd={(e)=>handleDrop(e,index)}
            >
              {/* <i class="bi bi-grip-vertical"></i> */}
            </div>
          )}
        </>
        // <div on >
        //   <i class="bi bi-grip-vertical"></i>
        // </div>
      ),
      sortable: false,
      // width: "15%",
    },
    {
      name: "Lektionen:",

      sortable: false,

      cell: (row, index) => (
        <StyledCell
          className={
            rowindex1 != null && rowindex1 == index
              ? `d-flex w-full dragon`
              : `d-flex w-full`
          }
        >
          {inputshow == row.lesson_id ? (
            <input
              type="text"
              name="editlessonname"
              defaultValue={row.lesson_name}
              //   value={}
              placeholder="lesson Name"
              className="order_input"
              onChange={handleEditChange}
            />
          ) : (
            <div className="d-flex w-full">
              {getRole == 1 ? (
                <div
                  key={index}
                  className={
                    rowindex1 != null && rowindex1 == index
                      ? `d-flex w-full dragon`
                      : `d-flex w-full`
                  }
                  style={{ cursor: "move" }}
                  draggable
                  onDragStart={(e) => handleDragStart(e, index)}
                  onDragOver={(e) => handleDragOver(e, index)}
                  // onDrop={(e) => handleDrop(e, index)}
                  // onDragEnd={(e)=>handleDrop(e,index)}
                >
                  {row.lesson_name}
                </div>
              ) : (
                <div
                  key={index}
                  // onDragEnd={(e)=>handleDrop(e,index)}
                >
                  {row.lesson_name}
                </div>
              )}
            </div>
          )}
        </StyledCell>
      ),
      // width: "55%",
    },
    {
      name: "",
      sortable: false,
      cell: (row, index) =>
        getRole === "1" ? (
          <StyledCell
            className={
              rowindex1 != null && rowindex1 == index
                ? `d-flex w-full filenameq w-100 dragon`
                : `filenameq w-100 d-flex w-full`
            }
          >
            {inputshow == row.lesson_id && oldfile != row.file_originalname ? (
              <div className="file-item d-flex ">
                <>
                  <div className="d-flex flex-column">
                    <i
                      className="bi bi-camera-reels"
                      title={row.file_originalname}
                    ></i>
                  </div>
                  <p className="m-auto">{row.file_originalname}</p>
                </>

                <div
                  className="cancle_icon"
                  onClick={() =>
                    handleEditRemoveFile(row.file_originalname, row.file_name)
                  }
                >
                  <i className="bi bi-x-circle-fill red_icon "></i>
                </div>
              </div>
            ) : (
              <>
                {oldfile != row.file_originalname ? (
                  <>{row.file_originalname}</>
                ) : (
                  <>
                    {oldfile == row.file_originalname && inputshow != 0 ? (
                      <div className="col-md-3 d-flex select-video w-100">
                        {EditfileValue.length === 0 && (
                          <label
                            htmlFor="lessoneditvideo"
                            className="custom-file-upload"
                          >
                            Dateien hochladen
                          </label>
                        )}
                        <input
                          type="file"
                          id="lessoneditvideo"
                          name="editfiles[]"
                          accept="video/*"
                          //   multiple="multiple"
                          className="inputfile"
                          onChange={(event) => {
                            const files = Array.from(event.target.files);
                            const videoFiles = files.filter((file) =>
                              file.type.startsWith("video/")
                            );
                            setEditFileValue(videoFiles);

                            onchangeinputorder1(videoFiles);
                            videoFiles.forEach((file) => {
                              handleEditImageChange(event, file);
                            });

                            const fileNames = Array.from(
                              event.target.files
                            ).map((file) => {
                              return file.name;
                            });
                            SetFilename(fileNames);
                          }}
                        />
                        <div className="img-previews">
                          {EditfileValue.map((file, index) => (
                            <div className="file-item" key={index}>
                              {file.type.startsWith("image/") ? (
                                <img
                                  src={file.preview}
                                  alt="Preview"
                                  style={{ height: "50px", width: "50px" }}
                                  title={file.name}
                                />
                              ) : (
                                <>
                                  <div className="d-flex flex-column">
                                    <i
                                      className="bi bi-camera-reels"
                                      title={file.name}
                                    ></i>
                                  </div>
                                </>
                              )}
                              <div
                                className="cancle_icon"
                                onClick={() => handleRemoveFile(file)}
                              >
                                <i className="bi bi-x-circle-fill red_icon"></i>
                              </div>
                            </div>
                          ))}
                        </div>
                        {EditfileValue.length > 0 && (
                          <div className="ps-2" style={{ width: "100%" }}>
                            <progress
                              value={imageeditUploadProgress}
                              max="100"
                            />
                            <span className="ps-2">{`${imageeditUploadProgress}%`}</span>
                          </div>
                        )}
                      </div>
                    ) : (
                      "Video not Found"
                    )}
                  </>
                )}
              </>
            )}
          </StyledCell>
        ) : (
          <></>
        ),
      // width: "30%",
    },
    {
      name: "",
      selector: (row, index) => customCell(row, click, index),
      sortable: false,
      // width: "15%",
    },
  ];

  const CustomEmptyState = () => (
    <div className="custom-empty-state p-3">Keine Eintrage vorhanden</div>
  );
  const [rowindex1, setRowIndex1] = useState(null);

  const handleDragStart = (e, rowIndex) => {
    e.dataTransfer.setData("rowIndex", rowIndex);
    setRowIndex1(rowIndex);
    // console.log(rowIndex);
    // console.log(data);
  };

  const handleDragOver = (e, rowIndex) => {
    e.preventDefault();
    // console.log(rowIndex);
  };

  const handleDrop = (e, rowIndex) => {
    const draggedRowIndex = e.dataTransfer.getData("rowIndex");
    const draggedRow = data[draggedRowIndex];
    console.log(draggedRowIndex);
    const newRows = data.filter((_, index) => index !== rowindex1);
    console.log(rowindex1);
    console.log(rowIndex);
    // Add the dragged row at the new position
    newRows.splice(rowIndex, 0, draggedRow);
    setRowIndex1(null);
    setData(newRows);
    const idsToUpdate = newRows.map((row) => row.lesson_id);
    // console.log(idsToUpdate);
    // axios({
    //   method: "POST",
    //   url: `${process.env.REACT_APP_BASE_URL}/course/sortorder/${courseId}`,
    //   data: {
    //     sort: idsToUpdate,
    //   },
    //   headers: {
    //     authorization: `Bearer ${token}`,
    //   },
    // })
    //   .then((res) => {
    //     console.log(res.data);
    //     fetchLessonsByCourseId();
    //     setIsLoading(false);
    //     getCourseById();
    //     // setTimeout(() => {
    //     //   toast.success(res.data.message);
    //     // }, 1000);
    //     // resetForm();
    //     // navigate(
    //     //   `/admin/course/lessons?course=${courseName}&&course_id=${courseId}`
    //     // );
    //   })
    //   .catch((error) => {
    //     toast.error(error.response.data.message);
    //     console.log(error);
    //   })
    //   .finally(() => {
    //     // setIsLoading(false); // Stop loading, whether success or error
    //     // setImageUploadProgress(0); // Reset the progress bar
    //   });
  };
  const customSortFunction = (a, b) => {
    // console.log(values1.course_sort_order);
    if (values1.course_sort_order && values1.course_sort_order != null) {
      const courseSortOrderArray = JSON?.parse(values1?.course_sort_order);
      const indexA = courseSortOrderArray.indexOf(a.lesson_id);
      const indexB = courseSortOrderArray.indexOf(b.lesson_id);
      return indexA - indexB;
    }
  };

  // Sort the data based on custom sorting function
  const sortedData = data.sort(customSortFunction);
  // console.log(rows);
  function handleSubmit1(event) {
    event.preventDefault(); // Prevents the default form submission behavior

    // Access form data
    const formData = new FormData(event.target);
    // const courseId = formData.get("courseid");
    const courseName = formData.get("coursename");
    const courseDesc = formData.get("coursedesc");
    formData.append("courseid2", courseId);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/update/course/${courseId}`,
      data: {
        coursename: courseName,
        coursedesc: courseDesc,
      },
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        // console.log(res.data);
        if (res.data.message === "Course updated successfully") {
          toast.success(res.data.message);
          navigate("/admin/course");
        } else {
          toast.error("Failed to update course");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }

  return (
    <>
      <div className="main-body" id="root1">
        <div
          className="order-header position-relative d-flex div"
          style={{ zIndex: "12" }}
        >
          <img src={bulb} className="my-auto" width={35} height={45} />
          <h3>{courseName}</h3>
          {/* <Link to={`addlesson?course_id=${courseId}`} className="order-btn">Add Lessons</Link> */}
        </div>

        {getRole === "1" ? (
          <div className="div emp-assign-box">
            <div className="emp-assign-box-inner">
              <div className="row">
                <label className="emp-box-label">Basics:</label>
                <form onSubmit={handleSubmit1}>
                  <div className="row justify-content-between">
                    <div className="col-md-1 d-none">
                      <input
                        type="text"
                        name="courseid"
                        defaultValue={courseId}
                        placeholder="Course Id"
                        className="order_input"
                        disabled
                      />
                    </div>
                    <div className="col-lg-7">
                      <div className=" d-flex mb-3">
                        <input
                          type="text"
                          name="coursename"
                          defaultValue={courseName}
                          placeholder="Course Name"
                          className="order_input"
                          // disabled
                        />
                        <div className="flex-row d-flex ms-2 save-course-btn">
                          <button type="submit" className="neworder-btn my-auto">
                            Speichern
                          </button>
                        </div>
                      </div>
                      <div>
                        <input
                          type="text"
                          name="coursedesc"
                          defaultValue={values1.course_description}
                          placeholder="Course Description"
                          className="order_input"
                          // disabled
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 select-box">
                      <Select
                        isMulti
                        name="emp"
                        placeholder="Mitarbeiter zuordnen"
                        options={empdata}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        value={empdata.filter((option) =>
                          selectedEmpData.includes(option.value)
                        )}
                        onChange={handleEmpChange}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : null}

        <div className="lesson-datatable">
          <div className="admin-dashboard">
            <div id="admin-dashboarddata">
              {/* <DataTable
              className="d-nones"
                columns={column}
                data={sortedData}
                noHeader={true}
                pagination={false}
                noDataComponent={<CustomEmptyState />}
                paginationComponentOptions={{
                  rowsPerPageText: "Anzahl/Seite:",
                  rangeSeparatorText: "von",
                }}
                paginationRowsPerPageOptions={[20, 30, 40]}
                conditionalRowStyles={[
                  {
                    when: (row) => row.orderstatus === 3,
                    style: {
                      background: "rgba(255, 255, 255, 0.04)",
                    },
                  },
                ]}
                onRowClicked={(row) => {
                  navigate(
                    `singlelesson?lesson=${row.lesson_name}&&lesson_id=${row.lesson_id}&&course_id=${row.course_id}`
                  );
                }}
                theme="solarized"
              ></DataTable> */}
   
              {/* <form className="" onSubmit={handleSubmit}>
                <div className="lesson-form-container">
                  {getRole === "1" ? (
                    <div className="lesson-form row justify-content-between mb-4">
                      <div className="col-md-6 d-none">
                        <input
                          type="text"
                          name="courseid"
                          value={courseId}
                          placeholder="Course Id"
                          className="order_input"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          name="lessonname"
                          value={values.lessonname}
                          placeholder="lesson Name"
                          className="order_input"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="error">
                        {errors.lessonname ? (
                          <small className="form-error">
                            {errors.lessonname}
                          </small>
                        ) : null}
                      </div>
                      <div className="col-md-3 d-flex select-video">
                        {fileValue.length === 0 && (
                          <label
                            htmlFor="lessonvideo"
                            className="custom-file-upload"
                          >
                            Dateien hochladen
                          </label>
                        )}
                        <input
                          type="file"
                          id="lessonvideo"
                          name="files[]"
                          multiple="multiple"
                          accept="video/*"
                          className="inputfile"
                          onChange={(event) => {
                            const files = Array.from(event.target.files);
                            const videoFiles = files.filter((file) =>
                              file.type.startsWith("video/")
                            );
                            setFileValue(videoFiles);

                            onchangeinputorder(videoFiles);
                            videoFiles.forEach((file) => {
                              handleImageChange(event, file);
                            });

                            const fileNames = Array.from(
                              event.target.files
                            ).map((file) => {
                              return file.name;
                            });
                            SetFilename(fileNames);
                          }}
                        />
                        <div className="img-previews">
                          {fileValue.map((file, index) => (
                            <div className="file-item" key={index}>
                              {file.type.startsWith("image/") ? (
                                <img
                                  src={file.preview}
                                  alt="Preview"
                                  style={{ height: "50px", width: "50px" }}
                                  title={file.name}
                                />
                              ) : (
                                <>
                                  <div className="d-flex flex-column">
                                    <i
                                      className="bi bi-camera-reels"
                                      title={file.name}
                                    ></i>
                                  </div>
                                </>
                              )}
                              <div
                                className="cancle_icon"
                                onClick={() => handleRemoveFile(file)}
                              >
                                <i className="bi bi-x-circle-fill red_icon"></i>
                              </div>
                            </div>
                          ))}
                        </div>
                        {fileValue.length > 0 && (
                          <div className="ps-2" style={{ width: "100%" }}>
                            <progress value={imageUploadProgress} max="100" />
                            <span className="ps-2">{`${imageUploadProgress}%`}</span>
                          </div>
                        )}
                      </div>
                      <div className="col-md-2 d-flex align-items-center">
                        <button type="submit" className="add-lesson">
                          hinzufügen
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              </form> */}
              <DraggableTable/>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

    </>
  );
};

export default Lessons;
