import React, { useRef, useState, useEffect } from "react";
import "./singlelesson.css";
import bulb from "../../../../Assets/menuicon/Group.png";
import { Link, useNavigate, useLocation, json } from "react-router-dom";
import plus from "../../../../Assets/menuicon/add.png";
import testvideo from "../../../../Assets/video/testvideo.mp4";
import { useFormik } from "formik";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TimePiker from "../Timepiker";
import dayjs from "dayjs";

const SingleLesson = () => {
  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const location = useLocation();
  const token = localStorage.getItem("token");
  const queryParams = new URLSearchParams(location.search);
  const courseId = queryParams.get("course_id");
  const lessonName = queryParams.get("lesson");
  const lesson_id = queryParams.get("lesson_id");
  const navigate = useNavigate();
  const getRole = localStorage.getItem("role");
  const [storedProgress, SetStoredProgress] = useState([]);
  const [topics, setTopics] = useState([]);
  const [videoFileName, setVideoFileName] = useState("");
  const videoRef = useRef(null);
  const id = localStorage.getItem("id");
  const [Loadlesson, setLoadlesson] = useState(false);

  useEffect(() => {
    fetchLessonsByLessonId();
  }, [lesson_id]);

  const fetchLessonsByLessonId = () => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/get/selected-lesson`,
      headers: {
        authorization: `Bearer ${token}`,
      },
      data: {
        lesson_id: lesson_id,
      },
    })
      .then((res) => {
        const topicTimeJSONString = res.data.data[0].topic_time;
        const matches = [
          ...topicTimeJSONString.matchAll(
            /\{"\$H":(\d+),"\$m":(\d+),"\$s":(\d+)\}/g
          ),
        ];

        const topicsData = res.data.data.reduce((acc, item, index) => {
          const topicNames = item.topic_name.split(",");
          const topics = topicNames.map((name, i) => ({
            name: name.trim(),
            time: matches[index * topicNames.length + i]
              ? matches[index * topicNames.length + i][0]
              : "0:0:0",
          }));
          return [...acc, ...topics];
        }, []);

        setTopics(topicsData);
        setVideoFileName(res.data.data[0].file_name);
      })
      .catch((error) => {
        // Handle error (e.g., show toast with error message)
      });
  };

  const initialValues = {
    lesson_id: lesson_id,
    topics: topics,
  };

  const [selectedTimes, setSelectedTimes] = useState([]);

  const parseTime = (timeString) => {
    const timeData = JSON.parse(timeString);
    const date = new Date();
    date.setHours(timeData["$H"]);
    date.setMinutes(timeData["$m"]);
    date.setSeconds(timeData["$s"]);
    return date;
  };

  const handleTimeChange = (index, time) => {
    const newSelectedTimes = [...selectedTimes];
    newSelectedTimes[index] = time;
    setSelectedTimes(newSelectedTimes);
  };
  // console.log(selectedTimes);

  const topicNameWithTime = [];
  topics.forEach((topic, index) => {
    const timeValue = selectedTimes[index];
    const topicObj = {
      name: topic.name,
      time: {
        $H: timeValue ? timeValue.$H : null,
        $m: timeValue ? timeValue.$m : null,
        $s: timeValue ? timeValue.$s : null,
      },
    };
    topicNameWithTime.push(topicObj);
  });
  // topicNameWithTime.forEach(topic => {
  //     console.log(topic.name);
  //     console.log(topic.time);
  // });

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    onSubmit: (data) => {
      // console.log(initialValues);
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/add/topic`,
        data: {
          lesson_id: lesson_id,
          topics: topicNameWithTime,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log(res.data);
          if (res.data.message === "Topics updated successfully") {
            toast.success("Topics updated successfully");
            setShowPopup(false);
            fetchLessonsByLessonId();
          }
        })
        .catch((error) => {
          toast.error("Failed to update topics");
          console.error(error);
        });
    },
  });

  const handleAddTopic = () => {
    setTopics([...topics, { name: "", time: "" }]);
  };

  const handleTopicChange = (index, field, event) => {
    const newTopics = [...topics];
    newTopics[index][field] = event.target.value;
    setTopics(newTopics);
  };

  // video cut using radio
  const handleRadioChange = (Lid, lessoname) => {
    console.log(id);
    console.log(storedProgress);
    if (
      storedProgress &&
      storedProgress.hasOwnProperty(Lid) &&
      storedProgress[Lid].status === 1
    ) {
      console.log("1");
      storedProgress[Lid].status = 0;
      storedProgress[Lid].video = 0;

      // setVideoProgress(0);
      const updatedProgress = { ...storedProgress };
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/lessonupdate/watch/${Lid}`,
        data: {
          videoarray: updatedProgress,
          id: id,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log(res.data);
          getEmployeewatchById(id);
        })
        .catch((error) => {
          // Handle error (e.g., show toast with error message)
        });
    } else if (
      storedProgress &&
      storedProgress.hasOwnProperty(Lid) &&
      storedProgress[Lid].status === 0
    ) {
      console.log("1");
      storedProgress[Lid].status = 1;
      storedProgress[Lid].video = 100;

      // setVideoProgress(0);
      const updatedProgress = { ...storedProgress };
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/lessonupdate/watch/${Lid}`,
        data: {
          videoarray: updatedProgress,
          id: id,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log(res.data);
          getEmployeewatchById(id);
        })
        .catch((error) => {
          // Handle error (e.g., show toast with error message)
        });
    } else {
      console.log("3");
      storedProgress[Lid] = {
        video: videoProgress,
        emp_id: id,
        status: 1,
      };
      const updatedProgress = { ...storedProgress };
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/lessonupdate/watch/${Lid}`,
        data: {
          videoarray: updatedProgress,
          id: id,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log(res.data);
          getEmployeewatchById(id);
        })
        .catch((error) => {
          // Handle error (e.g., show toast with error message)
        });
    }
  };
  // const handleRadioChange = (id, lessoname) => {
  //   const timeString = event.target.value;
  //   console.log(timeString);
  //   const match = timeString.match(/\{"\$H":(\d+),"\$m":(\d+),"\$s":(\d+)\}/);
  //   if (!match) {
  //       console.error("Invalid time string:", timeString);
  //       return;
  //   }

  //   const hours = parseInt(match[1]);
  //   const minutes = parseInt(match[2]);
  //   const seconds = parseInt(match[3]);

  //   const totalSeconds = hours * 3600 + minutes * 60 + seconds;
  //   videoRef.current.currentTime = totalSeconds;
  // };
  // video cut using radio end

  // video progress
  const [videoLength, setVideoLength] = useState(0);
  const [videoProgress, setVideoProgress] = useState(0);
  const [coursewatch, setCourseWatch] = useState(0);
  const [load, SetLoad] = useState(false);

  const updateVideoProgress = () => {
    if (videoRef.current && videoRef.current.duration) {
      const progress =
        (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setVideoProgress(progress);
    }
    requestAnimationFrame(updateVideoProgress);
  };

  useEffect(() => {
    // Start updating video progress
    updateVideoProgress();

    return () => {
      // Stop updating video progress when component unmounts
      cancelAnimationFrame(updateVideoProgress);
    };
  }, []);
  // video progress end

  const video = `${process.env.REACT_APP_VIDEO_URL}assets/newlessons/${videoFileName}`;
  // console.log(video);

  // fetchLessonsByCourseId start
  const [lessons, setLessons] = useState([]);
  const [lessoncount, setLessoncount] = useState(0);
  const [values1, setValues1] = useState([]);
  const fetchLessonsByCourseId = () => {};

  const getCourseById = () => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/get/course/byid/${courseId}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        // console.log(res.data.lessonCount);

        // console.log(res.data.data[0].course_sort_order);
        // SetLoad(true);
        setValues1(res.data.data[0].course_sort_order);
        setLessoncount(res.data.lessonCount);
      })
      .catch((res) => {
        // toast.error(res.response.message);
      });
  };
  const getEmployeewatchById = (id) => {
    // console.log("sdasd");
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/employee_watch`,
      data: {
        id: id,
      },
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        // console.log(res.data);
        const jsonObject = JSON.parse(res.data.data);
        SetStoredProgress(jsonObject);
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_BASE_URL}/lesson/get/by-course`,
          headers: {
            authorization: `Bearer ${token}`,
          },
          data: {
            courseId: courseId,
          },
        })
          .then((res) => {
            // console.log(res.data.data);
            // setInterval(setLessons(res.data.data), 500);

            if (res.data.data.length > 0) {
              // console.log(res.data.data.length > 0);
              // clearInterval(intervalId); // Stop the interval when res.data.data are found
              let statusCount = 0; // Initialize status count
              let courseSortOrder;
              // console.log(jsonObject);
              // Calculate status count
              res.data.data.forEach((filteredLesson) => {
                // console.log(jsonObject[filteredLesson.lesson_id]?.status);
                if (jsonObject[filteredLesson.lesson_id]?.status == 1) {
                  statusCount++; // Increment status count if status is 1
                }
              });
              // console.log(statusCount);
              if (parseFloat(statusCount) > 0) {
                // Calculate course watch percentage if status count is greater than 0

                const calculatedWatch = parseFloat(
                  (statusCount * 100) / res.data.data.length
                ).toFixed(2);
                // console.log("numbers:,,,",calculatedWatch);

                const watchValue = isFinite(calculatedWatch)
                  ? calculatedWatch
                  : 0; // Ensure value is finite
                setCourseWatch(watchValue);
              } else {
                // If status count is not greater than 0, set course watch to status count
                setCourseWatch(statusCount);
              }

              // SetLoad to true after updating course watch
              SetLoad(true);
              setLoadlesson(true);
              // console.log(coursewatch); // Output: [72, 75, 74, 73]
            }

            // getEmployeewatchById(id);
          })
          .catch((error) => {
            // Handle error (e.g., show toast with error message)
          });

        // console.log(jsonObject);
        // setValues1(res.data.data[0].course_sort_order);
      })
      .catch((res) => {
        // toast.error(res.response.message);
      });
  };
  // console.log(lessons);
  useEffect(() => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/lesson/get/by-course`,
      headers: {
        authorization: `Bearer ${token}`,
      },
      data: {
        courseId: courseId,
      },
    })
      .then((res) => {
        // console.log(res.data.data);
        // setInterval(setLessons(res.data.data), 500);

        setLessons(res.data.data);
        // setLessons(res.data.data);

        getEmployeewatchById(id);
      })
      .catch((error) => {
        // Handle error (e.g., show toast with error message)
      });

    getCourseById();
    SetLoad(true);
  }, [lesson_id, location]);
  useEffect(() => {
    // console.log(videoProgress);
    if (videoProgress == 100) {
      // console.log('sadsasd2133');
      // console.log(storedProgress);
      if (storedProgress && storedProgress.hasOwnProperty(lesson_id)) {
        // If the lesson_id exists, update the videoProgress and emp_id
        storedProgress[lesson_id].video = videoProgress;
        storedProgress[lesson_id].emp_id = id;
        storedProgress[lesson_id].status =
          parseFloat(videoProgress) == 100 ? 1 : 0;
      } else if (storedProgress) {
        // If the lesson_id doesn't exist, add a new entry for it
        storedProgress[lesson_id] = {
          video: videoProgress,
          emp_id: id,
          status: parseFloat(videoProgress) > 90 ? 1 : 0,
        };
      }

      const updatedProgress = { ...storedProgress };
      // console.log(updatedProgress);
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/lessonupdate/watch/${lesson_id}`,
        data: {
          videoarray: updatedProgress,
          id: id,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          if (videoProgress == 100) {
            getEmployeewatchById(id);
            // Check if values1 is not null
            if (values1 != null) {
              // Sort lessons based on values1
              const sortedLessons = lessons.sort((a, b) => {
                return (
                  values1.indexOf(a.lesson_id.toString()) -
                  values1.indexOf(b.lesson_id.toString())
                );
              });

              // Iterate over sorted lessons
              sortedLessons.forEach((lesson, index) => {
                // console.log(index);
                // console.log(lesson);
                if (lesson.lesson_id == lesson_id) {
                  let path;
                  // const indexvalue = sortedLessons[index + 1];
                  const sort = sortedLessons[index + 1];
                  if (sort === undefined) {
                    // console.log("sdsdsad");
                    window.location.reload();
                  }
                  // Determine path based on getRole
                  if (getRole === "1") {
                    path = `/admin/course/lessons/singlelesson?lesson=${sort.lesson_name}&&lesson_id=${sort.lesson_id}&&course_id=${sort.course_id}`;
                  } else if (getRole === "2") {
                    path = `/employee/course/lessons/singlelesson?lesson=${sort.lesson_name}&&lesson_id=${sort.lesson_id}&&course_id=${sort.course_id}`;
                  }
                  if (path) {
                    // console.log("asdsad");
                    navigate(path);
                  }
                }

                // Navigate to path if it exists
              });
            }
          }
          // setValues1(res.data.data[0].course_sort_order);
        })
        .catch((res) => {
          // toast.error(res.response.message);
        });
    }

    // console.log(lesson_id);
  }, [videoProgress, videoRef]);
  // fetchLessonsByCourseId end
  // console.log(load);
  if (Loadlesson == false) {
    return <img src={"https://i.gifer.com/VAyR.gif"} className="loader" />;
  } else {
    return (
      <>
        <div className="main-body" id="root1">
          <div
            className="order-header position-relative d-flex div"
            style={{ zIndex: "12" }}
          >
            <img src={bulb} className="my-auto" width={35} height={45} />
            <h3>{lessonName}</h3>
            <button className="video-progress">
              {load == true && coursewatch != null ? (
                <>{coursewatch}%</>
              ) : (
                <span class="loader1"></span>
              )}
            </button>
          </div>

          <div className="singlelesson-main-contener">
            <div className="singlelesson-row">
              <div className="singlelesson-left-columns">
                <video
                  className="singlelesson-video"
                  ref={videoRef}
                  controls
                  width="100%"
                  onTimeUpdate={() => {
                    if (videoProgress == 100) {
                      // Check if the video has reached the end

                      videoRef.current.pause();
                    }
                  }}
                >
                  {videoFileName ? (
                    <source
                      src={`${process.env.REACT_APP_IMG_URL}/assets/newlessons/${videoFileName}`}
                      type="video/mp4"
                    />
                  ) : (
                    <></>
                  )}
                </video>
              </div>
              <div className="singlelesson-right-columns">
                <p>Lektionen:</p>
                {values1 != null &&
                  lessons
                    .sort((a, b) => {
                      return (
                        values1.indexOf(a.lesson_id.toString()) -
                        values1.indexOf(b.lesson_id.toString())
                      );
                    })
                    .map((lesson, index) => (
                      <div key={index} className="lesson-rdio-container">
                        <div>
                          <input
                            className="lesson-radio"
                            type="checkbox"
                            multiple
                            id={`topic${index + 1}`}
                            name="topics"
                            // value={lesson.lesson_id}
                            defaultChecked={
                              storedProgress &&
                              storedProgress.hasOwnProperty(lesson.lesson_id) &&
                              storedProgress[lesson.lesson_id].status === 1
                            }
                            onChange={() =>
                              handleRadioChange(
                                lesson.lesson_id,
                                lesson.lesson_name
                              )
                            }
                          />
                        </div>
                        <div>
                          <label
                            onClick={() => {
                              let path;
                              if (getRole === "1") {
                                path = `/admin/course/lessons/singlelesson?lesson=${lesson.lesson_name}&&lesson_id=${lesson.lesson_id}&&course_id=${courseId}`;
                              } else if (getRole === "2") {
                                path = `/employee/course/lessons/singlelesson?lesson=${lesson.lesson_name}&&lesson_id=${lesson.lesson_id}&&course_id=${courseId}`;
                              }
                              if (path) {
                                navigate(path);
                              }
                            }}
                            className="lesson-label text-break"
                            htmlFor={`topic${index + 1}`}
                          >
                            {lesson.lesson_name}
                          </label>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>

        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </>
    );
  }
};

export default SingleLesson;
