import React from "react";
import { useState, useEffect } from "react";
import { useTable } from "react-table";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./table.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";
import styled from "styled-components";
import { confirmAlert } from "react-confirm-alert";

import { useFormik } from "formik";
import AddCourseSchema from "../AddCourseSchema";
import AddLessonsSchema from "./AddLessonsSchema";
import eye from "../../../../Assets/images/Vector (6).png";
import { event } from "jquery";

// Define your table columns and data

const columns = [
  {
    Header: "Lektionen:",
    accessor: "lesson_name",
  },
  {
    Header: "",
    accessor: "file_originalname",
  },
  {
    Header: "",
    accessor: "lesson_ids",
  },
];

const DraggableTable = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const [lessonName, setLessonName] = useState("");
  const navigate = useNavigate();

  const courseName = queryParams.get("course");
  const courseId = queryParams.get("course_id");
  const token = localStorage.getItem("token");
  const getRole = localStorage.getItem("role");
  const [values1, setValues1] = useState([]);
  const [data, setData] = useState([]);
  const [inputshow, setInputshow] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [orderfilechange, setorderFilechange] = useState([]);
  const [ordereditfilechange, setorderEditFilechange] = useState([]);
  const [fileValue, setFileValue] = useState([]);
  const [EditfileValue, setEditFileValue] = useState([]);
  const [imageUploadProgress, setImageUploadProgress] = useState(0);
  const [imageeditUploadProgress, setImageEditUploadProgress] = useState(0);
  // const rowRef = useRef(null);
  const [getFiles, setGetFiles] = useState([]);
  const [getEditFiles, setEditGetFiles] = useState([]);
  const [oldfile, setOldfile] = useState("");
  const [filereal, setfileReal] = useState("");
  const [filename, SetFilename] = useState([]);

  const fetchLessonsByCourseId = () => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/lesson/get/by-course`,
      headers: {
        authorization: `Bearer ${token}`,
      },
      data: {
        courseId: courseId,
      },
    })
      .then((res) => {
        setData(res.data.data);
        // console.log(res.data);
        // Handle success (e.g., show toast)
      })
      .catch((error) => {
        // Handle error (e.g., show toast with error message)
      });
  };

  const initialValues = {
    courseid: courseId,
    lessonname: "",
    lessondesc: "",
    topics: "",
    files: [],
  };
  const handleEditChange = (event) => {
    // Update the lessonName state with the new input value
    setLessonName(event.target.value);
  };
  // console.log(courseId);
  const { values, errors, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: AddLessonsSchema,
    onSubmit: (data) => {
      // console.log(data);
      const formData = new FormData();
      fileValue.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
      });

      let filenames = [];

      // Iterate through the orderfilechange array using forEach
      orderfilechange.forEach((file) => {
        // Assuming each item in orderfilechange is an object with a 'filename' property
        if (file.fileName) {
          // Push the fileName value into the filenames array
          filenames.push(file.fileName);
        }
      });

      // console.log(filenames);

      axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/lesson/add`,
        data: {
          ...data,
          files: orderfilechange,
          filenames: orderfilechange,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setTimeout(() => {
            toast.success(res.data.message);
          }, 1000);
          resetForm();
          navigate(
            `/admin/course/lessons?course=${courseName}&&course_id=${courseId}`
          );
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(error);
        })
        .finally(() => {
          // setIsLoading(false); // Stop loading, whether success or error
          setImageUploadProgress(0); // Reset the progress bar
        });
    },
  });
  // Sort the data based on custom sorting function

  // const data = ;
  // console.log(values1);

  const getCourseById = () => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/get/course/byid/${courseId}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        // console.log(res.data);
        setValues1(res.data.data[0]);
      })
      .catch((res) => {
        toast.error(res.response.message);
      });
  };
  useEffect(() => {
    getCourseById();
    fetchLessonsByCourseId();
  }, [courseId]);
  const customSortFunction = (a, b) => {
    // console.log(values1.course_sort_order);
    if (values1.course_sort_order && values1.course_sort_order != null) {
      const courseSortOrderArray = JSON?.parse(values1?.course_sort_order);
      const indexA = courseSortOrderArray.indexOf(a.lesson_id);
      const indexB = courseSortOrderArray.indexOf(b.lesson_id);
      return indexA - indexB;
    }
  };
  const sortedData = data.sort(customSortFunction);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: sortedData });
  const updatelesson = (lesson_id) => {
    // console.log(lesson_id);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/lesson/get/lessonid`,
      headers: {
        authorization: `Bearer ${token}`,
      },
      data: {
        lessonId: lesson_id,
      },
    })
      .then((res) => {
        // console.log(res.data);
        setInputshow(lesson_id);
        // const lessonId = res.data.data.map((item) => item.lesson_id);
        // const lessonName = res.data.data.map((item) => item.lesson_name);
        // const fileName = res.data.data.map((item) => item.file_name);
        // setValues({ lessonid: lessonId[0], lessonname: lessonName[0], fileName: fileName[0] });
        // if (fileName[0]) {
        //     // Set file value for preview
        //     setFileValue([{ name: fileName[0], preview: `${process.env.REACT_APP_BASE_URL}/${fileName[0]}` }]);
        // }
      })
      .catch((error) => {
        // Handle error (e.g., show toast with error message)
      });
  };
  const handlecancel = () => {
    setImageEditUploadProgress(0);
    setIsLoading(false);
    setInputshow(0);
    fetchLessonsByCourseId();
    setOldfile("");
    setfileReal("");
    setEditGetFiles([]);
    setorderEditFilechange([]);
    setLessonName("");
    setEditFileValue([]);
  };
  const onDragEnd = (result) => {
    if (result.destination) {
      const { source, destination, draggableId } = result;
      const startIndex = source.index;
      const endIndex = destination.index;

      // You can use this information to update your data or perform other actions
      console.log("Draggable ID:", draggableId);
      console.log("Start Index:", startIndex);
      console.log("End Index:", endIndex);
      // const draggedRowIndex = e.dataTransfer.getData("rowIndex");
      const draggedRow = data[startIndex];

      // console.log(draggedRowIndex);
      const newRows = data.filter((_, index) => index !== startIndex);
      // console.log(newRows);
      // Add the dragged row at the new position
      newRows.splice(endIndex, 0, draggedRow);

      //   setData(newRows);
      const idsToUpdate = newRows.map((row) => row.lesson_id);
      console.log(idsToUpdate);
      const updatedValues = {
        ...values,
        course_sort_order: JSON.stringify(idsToUpdate),
      };
      setValues1(updatedValues);
      setData(newRows);
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/course/sortorder/${courseId}`,
        data: {
          sort: idsToUpdate,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          console.log(res.data);

          console.log(values1);
          fetchLessonsByCourseId();
          // setIsLoading(false);
          // getCourseById();
          // setTimeout(() => {
          //   toast.success(res.data.message);
          // }, 1000);
          // resetForm();
          // navigate(
          //   `/admin/course/lessons?course=${courseName}&&course_id=${courseId}`
          // );
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(error);
        })
        .finally(() => {
          // setIsLoading(false); // Stop loading, whether success or error
          // setImageUploadProgress(0); // Reset the progress bar
        });
      // Further logic based on the result...
    }
  };
  const handleEditSubmit = async (
    id,
    lessonname,
    originalfilename,
    file_name
  ) => {
    // console.log("sadsadsad");
    setIsLoading(true); // Start loading
    // console.log(lessonName);
    // console.log(ordereditfilechange);
    // console.log(oldfile);
    // console.log(filereal);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/course/update/${id}`,
        {
          lessonName: lessonName == "" ? lessonname : lessonName,
          files: ordereditfilechange,
          filenames: ordereditfilechange,
          removefile: filereal,
          removeorginal: oldfile,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      setIsLoading(false);
      // console.log(response.data);

      // resetForm();
      // navigate(`/admin/course/lessons?course=${courseName}&&course_id=${courseId}`);
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error);
    } finally {
      setImageEditUploadProgress(0);
      setIsLoading(false);
      setInputshow(0);
      fetchLessonsByCourseId();
      setOldfile("");
      setfileReal("");
      setEditGetFiles([]);
      setorderEditFilechange([]);
      setLessonName("");
      setEditFileValue([]);
    }
    // setIsLoading(false); // Start loading
  };
  const click = (lesson_id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Möchten Sie diesen Datensatz löschen?",
      buttons: [
        {
          label: "Ja",
          onClick: () => deletelesson(lesson_id),
        },
        {
          label: "Nein",
        },
      ],
    });
  };
  const onchangeinputorder = (files) => {
    // console.log(files);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/lesson/filechange/`,
      headers: {
        authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
      data: {
        files: files,
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setImageUploadProgress(progress);
      },
    })
      .then((res) => {
        // console.log(res.data.uploadedFileNames);
        setorderFilechange(res.data.uploadedFileNames);
      })
      .catch((err) => {})
      .finally(() => {});
    // You can also perform additional actions, such as uploading the files to a server.
    // Example: uploadFilesToServer(files);
  };
  const handleImageChange = (event, file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      file.preview = reader.result;
      setGetFiles([...getFiles]);
    };
    reader.readAsDataURL(file);
  };
  const handleRemoveFile = (file) => {
    const updatedFiles = fileValue.filter((f) => f !== file);
    setFileValue(updatedFiles);
    const fileName = file.name;
    const foundFiles = [];

    orderfilechange.forEach((file) => {
      if (file.originalname === fileName) {
        foundFiles.push(file.fileName);
      }
    });
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/lesson/filechangedelete/`,
      headers: {
        authorization: `Bearer ${token}`,
      },
      data: { text: foundFiles },
    })
      .then((res) => {
        setorderFilechange(
          orderfilechange.filter((file) => file.originalname !== fileName)
        );

        // chatonchange1 = [];
      })
      .catch((res) => {
        // toast.error(res.response.message)
      })
      .finally(() => {
        // setIsLoading(false); // Stop loading, whether success or error
      });
  };
  const handleEditRemoveFile = (originalfilename, filename) => {
    setOldfile(originalfilename);
    setfileReal(filename);
  };
  const onchangeinputorder1 = (files) => {
    // console.log(files);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/lesson/filechange/`,
      headers: {
        authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
      data: {
        files: files,
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setImageEditUploadProgress(progress);
      },
    })
      .then((res) => {
        // console.log(res.data.uploadedFileNames);
        setorderEditFilechange(res.data.uploadedFileNames);
      })
      .catch((err) => {})
      .finally(() => {});
    // You can also perform additional actions, such as uploading the files to a server.
    // Example: uploadFilesToServer(files);
  };
  const handleEditImageChange = (event, file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      file.preview = reader.result;
      setEditGetFiles([...getEditFiles]);
    };
    reader.readAsDataURL(file);
  };
  const deletelesson = (lesson_id) => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/lesson/remove/${lesson_id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        // console.log(res.data);
        toast.success(res.data.message, {
          toastId: "unique-random-text-xAu9C9-",
        });
        fetchLessonsByCourseId();
      })
      .catch((res) => {
        toast.error(res.response.data.message);
      });
  };
  const handleRowClick = () => {};
  const handleClickRow = (event, lesson_name, lesson_id, course_id) => {
    // Check if the click originated from a child td element
    // console.log("sdsadasd");
    if (!event.target.closest("td")) {
      // Click originated from empty space within tr
      // Perform your redirect or action logic here
      navigate(
        `singlelesson?lesson=${lesson_name}&&lesson_id=${lesson_id}&&course_id=${course_id}`
      );
      // console.log("Row clicked! (Empty space)");
    }
  };
  return (
    <div className="outer_border_draggable">
      <div className="Dragable_table">
        {rows.length > 0 ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <table className="d-flex flex-column w-100" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <Droppable droppableId="table">
                {(provided) => (
                  <tbody {...getTableBodyProps()} ref={provided.innerRef}>
                    {rows.map((row, index) => {
                      prepareRow(row);
                      // console.log(row);
                      return (
                        <>
                          {getRole == 1 && (
                            <Draggable
                              key={row.id}
                              draggableId={row.id}
                              index={index}
                            >
                              {(provided) => (
                                <tr
                                  // ref={rowRef}
                                  onClick={(event) => {
                                    handleClickRow(
                                      event,
                                      row.original.lesson_name,
                                      row.original.lesson_id,
                                      row.original.course_id
                                    );
                                  }}
                                  {...row.getRowProps()}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {row.cells.map((cell, index) => (
                                    <React.Fragment key={index}>
                                      {index === 0 ? (
                                        // Custom row for cell number 3
                                        <td {...cell.getCellProps()}>
                                          {inputshow ==
                                          row.original.lesson_id ? (
                                            <input
                                              type="text"
                                              name="editlessonname"
                                              defaultValue={
                                                row.original.lesson_name
                                              }
                                              //   value={}
                                              placeholder="lesson Name"
                                              className="order_input"
                                              onChange={handleEditChange}
                                            />
                                          ) : (
                                            <div className="d-flex w-full">
                                              {getRole == 1 ? (
                                                <div
                                                  key={index}
                                                  style={{ cursor: "move" }}
                                                  // onClick={() => {
                                                  //   navigate(
                                                  //     `singlelesson?lesson=${row.original.lesson_name}&&lesson_id=${row.original.lesson_id}&&course_id=${row.original.course_id}`
                                                  //   );
                                                  // }}
                                                  // onDragEnd={(e)=>handleDrop(e,index)}
                                                >
                                                  {cell.render("Cell")}
                                                </div>
                                              ) : (
                                                <div
                                                  key={index}
                                                  // onClick={() => {
                                                  //   navigate(
                                                  //     `singlelesson?lesson=${row.original.lesson_name}&&lesson_id=${row.original.lesson_id}&&course_id=${row.original.course_id}`
                                                  //   );
                                                  // }}
                                                  // onDragEnd={(e)=>handleDrop(e,index)}
                                                >
                                                  {cell.render("Cell")}
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </td>
                                      ) : index === 1 ? (
                                        // Render regular cells for other indices
                                        <td {...cell.getCellProps()}>
                                          {inputshow ==
                                            row.original.lesson_id &&
                                          oldfile !=
                                            row.original.file_originalname ? (
                                            <div className="file-item d-flex ">
                                              <>
                                                <div className="d-flex flex-column">
                                                  <i
                                                    className="bi bi-camera-reels"
                                                    title={
                                                      row.original
                                                        .file_originalname
                                                    }
                                                  ></i>
                                                </div>
                                                <p
                                                  // onClick={() => {
                                                  //   navigate(
                                                  //     `singlelesson?lesson=${row.original.lesson_name}&&lesson_id=${row.original.lesson_id}&&course_id=${row.original.course_id}`
                                                  //   );
                                                  // }}
                                                  className="m-auto"
                                                >
                                                  {
                                                    row.original
                                                      .file_originalname
                                                  }
                                                </p>
                                              </>

                                              <div
                                                className="cancle_icon"
                                                onClick={() =>
                                                  handleEditRemoveFile(
                                                    row.original
                                                      .file_originalname,
                                                    row.original.file_name
                                                  )
                                                }
                                              >
                                                <i className="bi bi-x-circle-fill red_icon "></i>
                                              </div>
                                            </div>
                                          ) : (
                                            <>
                                              {oldfile !=
                                              row.original.file_originalname ? (
                                                <div
                                                // onClick={() => {
                                                //   navigate(
                                                //     `singlelesson?lesson=${row.original.lesson_name}&&lesson_id=${row.original.lesson_id}&&course_id=${row.original.course_id}`
                                                //   );
                                                // }}
                                                >
                                                  {
                                                    row.original
                                                      .file_originalname
                                                  }
                                                </div>
                                              ) : (
                                                <>
                                                  {oldfile ==
                                                    row.original
                                                      .file_originalname &&
                                                  inputshow != 0 ? (
                                                    <div className="col-md-3 d-flex select-video w-100">
                                                      {EditfileValue.length ===
                                                        0 && (
                                                        <label
                                                          htmlFor="lessoneditvideo"
                                                          className="custom-file-upload"
                                                        >
                                                          Dateien hochladen
                                                        </label>
                                                      )}
                                                      <input
                                                        type="file"
                                                        id="lessoneditvideo"
                                                        name="editfiles[]"
                                                        accept="video/*"
                                                        //   multiple="multiple"
                                                        className="inputfile"
                                                        onChange={(event) => {
                                                          const files =
                                                            Array.from(
                                                              event.target.files
                                                            );
                                                          const videoFiles =
                                                            files.filter(
                                                              (file) =>
                                                                file.type.startsWith(
                                                                  "video/"
                                                                )
                                                            );
                                                          setEditFileValue(
                                                            videoFiles
                                                          );

                                                          onchangeinputorder1(
                                                            videoFiles
                                                          );
                                                          videoFiles.forEach(
                                                            (file) => {
                                                              handleEditImageChange(
                                                                event,
                                                                file
                                                              );
                                                            }
                                                          );

                                                          const fileNames =
                                                            Array.from(
                                                              event.target.files
                                                            ).map((file) => {
                                                              return file.name;
                                                            });
                                                          SetFilename(
                                                            fileNames
                                                          );
                                                        }}
                                                      />
                                                      <div className="img-previews">
                                                        {EditfileValue.map(
                                                          (file, index) => (
                                                            <div
                                                              className="file-item"
                                                              key={index}
                                                            >
                                                              {file.type.startsWith(
                                                                "image/"
                                                              ) ? (
                                                                <img
                                                                  src={
                                                                    file.preview
                                                                  }
                                                                  alt="Preview"
                                                                  style={{
                                                                    height:
                                                                      "50px",
                                                                    width:
                                                                      "50px",
                                                                  }}
                                                                  title={
                                                                    file.name
                                                                  }
                                                                />
                                                              ) : (
                                                                <>
                                                                  <div className="d-flex flex-column">
                                                                    <i
                                                                      className="bi bi-camera-reels"
                                                                      title={
                                                                        file.name
                                                                      }
                                                                    ></i>
                                                                  </div>
                                                                </>
                                                              )}
                                                              <div
                                                                className="cancle_icon"
                                                                onClick={() =>
                                                                  handleRemoveFile(
                                                                    file
                                                                  )
                                                                }
                                                              >
                                                                <i className="bi bi-x-circle-fill red_icon"></i>
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                      {EditfileValue.length >
                                                        0 && (
                                                        <div
                                                          className="ps-2"
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                        >
                                                          <progress
                                                            value={
                                                              imageeditUploadProgress
                                                            }
                                                            max="100"
                                                          />
                                                          <span className="ps-2">{`${imageeditUploadProgress}%`}</span>
                                                        </div>
                                                      )}
                                                    </div>
                                                  ) : (
                                                    "Video not Found"
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </td>
                                      ) : (
                                        <td
                                          // onClick={() => {
                                          //   navigate(
                                          //     `singlelesson?lesson=${row.original.lesson_name}&&lesson_id=${row.original.lesson_id}&&course_id=${row.original.course_id}`
                                          //   );
                                          // }}
                                          {...cell.getCellProps()}
                                        >
                                          {cell.render("Cell")}
                                        </td>
                                      )}
                                    </React.Fragment>
                                  ))}
                                  <td>
                                    <>
                                      {inputshow == row.original.lesson_id ? (
                                        <div className="d-flex lessons gap-2">
                                          <button
                                            type="button"
                                            onClick={() => {
                                              handleEditSubmit(
                                                row.original.lesson_id,
                                                row.original.lesson_name,
                                                row.original.file_name,
                                                row.original.file_originalname
                                              );
                                            }}
                                            className="order-btn  w-100"
                                            disabled={isLoading}
                                          >
                                            {/* sdsadsadas */}
                                            {isLoading
                                              ? "Wird bearbeitet..."
                                              : // Replace with your loader icon
                                                "Speichern"}
                                          </button>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              handlecancel();
                                            }}
                                            className=" cancel_btn w-100"
                                            disabled={isLoading}
                                          >
                                            {/* sdsadsadas */}
                                            Cancel
                                          </button>
                                        </div>
                                      ) : (
                                        <div className="d-flex">
                                          {getRole === "1" ? (
                                            <div className="me-3 my-auto ">
                                              {/* <Link> */}
                                              <svg
                                                className="my-auto d-flex"
                                                onClick={() =>
                                                  updatelesson(
                                                    row.original.lesson_id
                                                  )
                                                }
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  id="Vector"
                                                  d="M0 14.2505V18H3.74949L14.808 6.9415L11.0585 3.19201L0 14.2505ZM17.7076 4.0419C17.8003 3.94939 17.8738 3.83952 17.924 3.71856C17.9742 3.59761 18 3.46794 18 3.33699C18 3.20604 17.9742 3.07638 17.924 2.95542C17.8738 2.83446 17.8003 2.72459 17.7076 2.63209L15.3679 0.292404C15.2754 0.199713 15.1655 0.126176 15.0446 0.0760011C14.9236 0.0258265 14.794 0 14.663 0C14.5321 0 14.4024 0.0258265 14.2814 0.0760011C14.1605 0.126176 14.0506 0.199713 13.9581 0.292404L12.1284 2.12216L15.8778 5.87165L17.7076 4.0419Z"
                                                  fill={`${
                                                    row.orderstatus == 3
                                                      ? "rgb(120,120,120)"
                                                      : "#BCBCBC"
                                                  }`}
                                                />
                                              </svg>
                                              {/* </Link> */}
                                            </div>
                                          ) : null}

                                          {getRole === "1" ? (
                                            <div className="ms-2 my-auto">
                                              <svg
                                                className="my-auto d-flex"
                                                onClick={() =>
                                                  click(row.original.lesson_id)
                                                }
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <g id="Frame">
                                                  <path
                                                    id="Vector"
                                                    d="M5.99878 19C5.99878 20.1 6.89878 21 7.99878 21H15.9988C17.0988 21 17.9988 20.1 17.9988 19V7H5.99878V19ZM18.9988 4H15.4988L14.4988 3H9.49878L8.49878 4H4.99878V6H18.9988V4Z"
                                                    fill={`${
                                                      row.orderstatus == 3
                                                        ? "rgb(120,120,120)"
                                                        : "#BCBCBC"
                                                    }`}
                                                  />
                                                </g>
                                              </svg>
                                            </div>
                                          ) : (
                                            <div className="me-3 my-auto">
                                              <Link
                                                to={`singlelesson?lesson=${row.original.lesson_name}&&lesson_id=${row.original.lesson_id}&&course_id=${row.original.course_id}`}
                                                className="d-flex align-items-center"
                                              >
                                                <img
                                                  src={eye}
                                                  height="18"
                                                  width="25"
                                                />
                                              </Link>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          )}
                          {getRole != 1 && (
                            <tr
                              onClick={() => {
                                navigate(
                                  `singlelesson?lesson=${row.original.lesson_name}&&lesson_id=${row.original.lesson_id}&&course_id=${row.original.course_id}`
                                );
                              }}
                              {...row.getRowProps()}
                            >
                              {row.cells.map((cell, index) =>
                                index == 0 ? (
                                  <td key={index} {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                ) : (
                                  <></>
                                )
                              )}
                              <td>
                                <div className="me-3 my-auto">
                                  <button
                                    // onClick={() => {
                                    //   navigate(
                                    //     `singlelesson?lesson=${row.original.lesson_name}&&lesson_id=${row.original.lesson_id}&&course_id=${row.original.course_id}`
                                    //   );

                                    // }}
                                    className="neworder-btn w-100 my-auto"
                                  >
                                    Jetzt ansehen
                                  </button>
                                  {/* <Link
                                    to={`singlelesson?lesson=${row.original.lesson_name}&&lesson_id=${row.original.lesson_id}&&course_id=${row.original.course_id}`}
                                    className="d-flex align-items-center"
                                  >
                                    <img src={eye} height="18" width="25" />
                                  </Link> */}
                                </div>
                              </td>
                            </tr>
                          )}
                        </>
                      );
                    })}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </table>
          </DragDropContext>
        ) : (
          <div class="custom-empty-state mx-auto p-3">
            Keine Eintrage vorhanden
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit}>
        <div className="lesson-form-container">
          {getRole === "1" ? (
            <div className="lesson-form row justify-content-between mb-4">
              <div className="col-md-6 d-none">
                <input
                  type="text"
                  name="courseid"
                  value={courseId}
                  placeholder="Course Id"
                  className="order_input"
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  name="lessonname"
                  value={values.lessonname}
                  placeholder="lesson Name"
                  className="order_input"
                  onChange={handleChange}
                />
              </div>
              <div className="error">
                {errors.lessonname ? (
                  <small className="form-error">{errors.lessonname}</small>
                ) : null}
              </div>
              <div className="col-md-3 d-flex select-video">
                {fileValue.length === 0 && (
                  <label htmlFor="lessonvideo" className="custom-file-upload">
                    Dateien hochladen
                  </label>
                )}
                <input
                  type="file"
                  id="lessonvideo"
                  name="files[]"
                  multiple="multiple"
                  accept="video/*"
                  className="inputfile"
                  onChange={(event) => {
                    const files = Array.from(event.target.files);
                    const videoFiles = files.filter((file) =>
                      file.type.startsWith("video/")
                    );
                    setFileValue(videoFiles);

                    onchangeinputorder(videoFiles);
                    videoFiles.forEach((file) => {
                      handleImageChange(event, file);
                    });

                    const fileNames = Array.from(event.target.files).map(
                      (file) => {
                        return file.name;
                      }
                    );
                    SetFilename(fileNames);
                  }}
                />
                <div className="img-previews">
                  {fileValue.map((file, index) => (
                    <div className="file-item" key={index}>
                      {file.type.startsWith("image/") ? (
                        <img
                          src={file.preview}
                          alt="Preview"
                          style={{ height: "50px", width: "50px" }}
                          title={file.name}
                        />
                      ) : (
                        <>
                          <div className="d-flex flex-column">
                            <i
                              className="bi bi-camera-reels"
                              title={file.name}
                            ></i>
                          </div>
                        </>
                      )}
                      <div
                        className="cancle_icon"
                        onClick={() => handleRemoveFile(file)}
                      >
                        <i className="bi bi-x-circle-fill red_icon"></i>
                      </div>
                    </div>
                  ))}
                </div>
                {fileValue.length > 0 && (
                  <div className="ps-2" style={{ width: "100%" }}>
                    <progress value={imageUploadProgress} max="100" />
                    <span className="ps-2">{`${imageUploadProgress}%`}</span>
                  </div>
                )}
              </div>
              <div className="col-md-2 d-flex align-items-center">
                <button type="submit" className="add-lesson">
                  hinzufügen
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
};

export default DraggableTable;
