import React, { useEffect, useState } from 'react';
import "./lessons.css";
import bulb from "../../../../Assets/menuicon/Group.png";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import styled from "styled-components";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import aerro from "../../../../Assets/menuicon/Vector (7).png";
import Select from 'react-select';
import { useFormik } from "formik";
import AddCourseSchema from "../AddCourseSchema";
import AddLessonsSchema from "./AddLessonsSchema";


const UpdateLesson = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const courseName = queryParams.get('course');
    const courseId = queryParams.get('course_id');
    const token = localStorage.getItem("token");

    const { lesson_id } = useParams();

    // get selected emp
    const selectedemp = () => {
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_BASE_URL}/getselected/allemp`,
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: {
                courseId: courseId,
            },
        })
            .then((res) => {
                const empIds = res.data.data.map(item => item.emp);
                const empIdsArray = empIds.join(',').split(',').map(id => parseInt(id.trim()));
                // console.log(empIdsArray);
                setSelectedEmpData(empIdsArray);
            })
            .catch((res) => {
                // setIsLoaded(true);
                toast.error(res.response.message);
            });
    };
    useEffect(() => {
        selectedemp();
    }, []);
    // get selected emp end

    // emp add in course
    const [empdata, setEmpdata] = useState([]);
    const [selectedEmpData, setSelectedEmpData] = useState([]);

    const handleEmpChange = (selectedOptions) => {

        const empValuesArray = selectedOptions.map(option => option.value);
        const empValuesString = empValuesArray.join(',');
        setSelectedEmpData(selectedOptions.map(option => option.value));
        // console.log(empValuesString);

        axios({
            method: "POST",
            url: `${process.env.REACT_APP_BASE_URL}/add/emp`,
            data: {
                courseId: courseId,
                empString: empValuesString,
            },
            headers: {
                authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                console.log(res.data);
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });

    };
    // emp add in course end

    const navigate = useNavigate();

    // get emp
    useEffect(() => {
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_BASE_URL}/emp/get`,
            headers: {
                authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                const options = res.data.data.map(item => ({
                    value: item.id,
                    label: item.fname
                }));
                setEmpdata(options);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    // add lesson code
    const [fileValue, setFileValue] = useState([]);
    const [orderfilechange, setorderFilechange] = useState([]);
    const [imageUploadProgress, setImageUploadProgress] = useState(0);
    const [getFiles, setGetFiles] = useState([]);
    const [filename, SetFilename] = useState([]);

    const handleImageChange = (event, file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            file.preview = reader.result;
            setGetFiles([...getFiles]);
        };
        reader.readAsDataURL(file);
    };


    const handleRemoveFile = (file) => {
        const updatedFiles = fileValue.filter((f) => f !== file);
        setFileValue(updatedFiles);
        const fileName = file.name;
        const foundFiles = [fileName];

        // console.log(foundFiles);

        orderfilechange.forEach((file) => {
            if (file.originalname === fileName) {
                foundFiles.push(file.fileName);
            }
        });
        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/lesson/filechangedelete/`,
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: { text: foundFiles },
        })
            .then((res) => {
                // console.log(res.data);
                setorderFilechange(
                    orderfilechange.filter((file) => file.originalname !== fileName)
                );

                // chatonchange1 = [];
            })
            .catch((res) => {
                // toast.error(res.response.message)
            })
            .finally(() => {
                // setIsLoading(false); // Stop loading, whether success or error
            });
    };


    const onchangeinputorder = (files) => {
        // console.log(files);
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_BASE_URL}/lesson/filechange/`,
            headers: {
                authorization: `Bearer ${token}`,
                "content-type": "multipart/form-data",
            },
            data: {
                files: files,
            },
            onUploadProgress: (progressEvent) => {
                const progress = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setImageUploadProgress(progress);
            },
        })
            .then((res) => {
                // console.log(res.data.uploadedFileNames);
                setorderFilechange(res.data.uploadedFileNames);
            })
            .catch((err) => { })
            .finally(() => { });
        // You can also perform additional actions, such as uploading the files to a server.
        // Example: uploadFilesToServer(files);
    };


    // Get Lessons From LessonId
    const [values, setValues] = useState({ lessonid: "", lessonname: "", fileName: "" });
    const fetchLessonsByLessonId = () => {
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_BASE_URL}/lesson/get/lessonid`,
            headers: {
                authorization: `Bearer ${token}`,
            },
            data: {
                lessonId: lesson_id,
            },
        })
            .then((res) => {
                const lessonId = res.data.data.map((item) => item.lesson_id);
                const lessonName = res.data.data.map((item) => item.lesson_name);
                const fileName = res.data.data.map((item) => item.file_name);
                setValues({ lessonid: lessonId[0], lessonname: lessonName[0], fileName: fileName[0] });
                if (fileName[0]) {
                    // Set file value for preview
                    setFileValue([{ name: fileName[0], preview: `${process.env.REACT_APP_BASE_URL}/${fileName[0]}` }]);
                }
            })
            .catch((error) => {
                // Handle error (e.g., show toast with error message)
            });
    };

    useEffect(() => {
        fetchLessonsByLessonId();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData();
            fileValue.forEach((file, index) => {
                formData.append(`files[${index}]`, file);
            });

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/lesson/update`, {
                ...values,
                files: orderfilechange,
                filenames: orderfilechange,
            }, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            });

            // console.log(response.data);
            // resetForm();
            navigate(`/admin/course/lessons?course=${courseName}&&course_id=${courseId}`);
            toast.success(response.data.message);
        } catch (error) {
            toast.error(error.response.data.message);
            console.error(error);
        } finally {
            setImageUploadProgress(0);
        }
    };

    const CustomEmptyState = () => (
        <div className="custom-empty-state p-3">Keine Eintrage vorhanden</div>
    );

    return (
        <>
            <div className="main-body" id="root1">
                <div className="order-header position-relative d-flex div" style={{ zIndex: "12" }}>
                    <img src={bulb} className="my-auto" width={35} height={45} />
                    <h3>{courseName}</h3>
                    {/* <Link to={`addlesson?course_id=${courseId}`} className="order-btn">Add Lessons</Link> */}
                </div>

                <div className="div emp-assign-box">
                    <div className="emp-assign-box-inner">
                        <div className="row">
                            <label className="emp-box-label">Basics:</label>
                            <form>
                                <div className="row justify-content-between">
                                    <div className="col-md-1 d-none">
                                        <input
                                            type="text"
                                            name="courseid"
                                            value={courseId}
                                            placeholder="Course Id"
                                            className="order_input"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-7">
                                        <input
                                            type="text"
                                            name="coursename"
                                            value={courseName}
                                            placeholder="Course Name"
                                            className="order_input"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 select-box">
                                        <Select
                                            isMulti
                                            name="emp"
                                            placeholder="Mitarbeiter zuordnen"
                                            options={empdata}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            value={empdata.filter(option => selectedEmpData.includes(option.value))}
                                            onChange={handleEmpChange}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="update-lesson-datatable">
                    <div className="admin-dashboard">
                        <div id="admin-dashboarddata">
                            <DataTable
                                // data={data}
                                noHeader={true}
                                pagination={false}
                                paginationPerPage={20}
                                noDataComponent={<CustomEmptyState />}
                                paginationComponentOptions={{
                                    rowsPerPageText: "Anzahl/Seite:",
                                    rangeSeparatorText: "von",
                                }}
                                paginationRowsPerPageOptions={[20, 30, 40]}
                                conditionalRowStyles={[
                                    {
                                        when: (row) => row.orderstatus === 3,
                                        style: {
                                            background: "rgba(255, 255, 255, 0.04)",
                                        },
                                    },
                                ]}
                                onRowClicked={(row) => {
                                    navigate(`"#"`);
                                }}
                                theme="solarized"
                            />

                            <form onSubmit={handleSubmit}>
                                <div className='update-lesson-form-container'>
                                    <div className='lesson-form row justify-content-between mb-4'>
                                        <div className='col-md-6 d-none'>
                                            <input
                                                type="text"
                                                name="lessonid"
                                                value={values.lessonid}
                                                placeholder="Lesson Id"
                                                className="order_input"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                type="text"
                                                name="lessonname"
                                                value={values.lessonname}
                                                placeholder="Lesson Name"
                                                className="order_input"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        {/* <div className="error">
                                            {errors.lessonname ? (
                                                <small className="form-error">{errors.lessonname}</small>
                                            ) : null}
                                        </div> */}
                                        <div className='col-md-3 d-flex select-video'>
                                            {!fileValue.length ? (
                                                <label htmlFor="lessonvideo" className="custom-file-upload">Dateien hochladen</label>
                                            ) : null}
                                            <input
                                                type="file"
                                                id="lessonvideo"
                                                name="files[]"
                                                multiple="multiple"
                                                className="inputfile"
                                                style={{ display: fileValue.length ? 'none' : 'block' }}
                                                onChange={(event) => {
                                                    const files = Array.from(event.target.files);
                                                    setFileValue(files);

                                                    onchangeinputorder(files);
                                                    files.forEach((file) => {
                                                        handleImageChange(event, file);
                                                    });

                                                    const fileNames = Array.from(event.target.files).map(
                                                        (file) => {
                                                            return file.name;
                                                        }
                                                    );
                                                    SetFilename(fileNames);
                                                }}
                                            />
                                            <div className="img-previews">
                                                {fileValue.map((file, index) => (
                                                    <div className="file-item" key={index}>
                                                        {file.type && file.type.startsWith("image/") ? (
                                                            <img
                                                                src={file.preview}
                                                                alt="Preview"
                                                                style={{ height: "50px", width: "50px" }}
                                                                title={file.name}
                                                            />
                                                        ) : (
                                                            <div className="d-flex flex-column">
                                                                <i className="bi bi-camera-reels" title={file.name}></i>
                                                            </div>
                                                        )}
                                                        <div
                                                            className="cancle_icon"
                                                            onClick={() => handleRemoveFile(file)}
                                                        >
                                                            <i className="bi bi-x-circle-fill red_icon"></i>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            {fileValue.length > 0 ? (
                                                <div className="ps-2" style={{ width: "100%" }}>
                                                    <progress value={imageUploadProgress} max="100" />
                                                    <span className="ps-2">{`${imageUploadProgress}%`}</span>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <div className='col-md-2 d-flex align-items-center'>
                                            <button type="submit" className="add-lesson">hinzufügen</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </>
    )
}

export default UpdateLesson;