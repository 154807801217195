import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./employee.css";
import DataTable, { createTheme } from "react-data-table-component";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

const Employees = () => {
  const customCell = (row, func2) => (
    <div className="d-flex">
      <div className="me-4 my-auto">
        <Link to={`/admin/employee/updateemployee/${row.id}`}>
          <svg
            className="my-auto d-flex"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="Vector"
              d="M0 14.2505V18H3.74949L14.808 6.9415L11.0585 3.19201L0 14.2505ZM17.7076 4.0419C17.8003 3.94939 17.8738 3.83952 17.924 3.71856C17.9742 3.59761 18 3.46794 18 3.33699C18 3.20604 17.9742 3.07638 17.924 2.95542C17.8738 2.83446 17.8003 2.72459 17.7076 2.63209L15.3679 0.292404C15.2754 0.199713 15.1655 0.126176 15.0446 0.0760011C14.9236 0.0258265 14.794 0 14.663 0C14.5321 0 14.4024 0.0258265 14.2814 0.0760011C14.1605 0.126176 14.0506 0.199713 13.9581 0.292404L12.1284 2.12216L15.8778 5.87165L17.7076 4.0419Z"
              fill={`${row.orderstatus == 3 ? "rgb(120,120,120)" : "#BCBCBC"}`}
            />
          </svg>
        </Link>
      </div>
      <div className="my-auto">
        <svg
          className="my-auto d-flex"
          onClick={() => func2(row.id)}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Frame">
            <path
              id="Vector"
              d="M5.99878 19C5.99878 20.1 6.89878 21 7.99878 21H15.9988C17.0988 21 17.9988 20.1 17.9988 19V7H5.99878V19ZM18.9988 4H15.4988L14.4988 3H9.49878L8.49878 4H4.99878V6H18.9988V4Z"
              fill={`${row.orderstatus == 3 ? "rgb(120,120,120)" : "#BCBCBC"}`}
            />
          </g>
        </svg>
      </div>
    </div>
  );
  const columns = [
    {
      name: "Bild ",
      selector: (row) =>
        row.userImg ? (
          <img
            src={`${process.env.REACT_APP_IMG_URL}/assets/profilepic/${row.userImg}`}
            style={{ height: "50px", width: "50px", borderRadius: "50%" }}
          />
        ) : (
          <div
            style={{
              height: "50px",
              width: "50px",
              borderRadius: "50%",
              background: "grey",
            }}
          ></div>
        ),
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.fname + " " + row.lname,
      sortable: true,
    },
    {
      name: "Nr.",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Neu",
      selector: (row) => row.allOrderStatus[1],
      sortable: true,
    },
    {
      name: "Dran",
      selector: (row) => row.allOrderStatus[2],
      sortable: true,
    },
    {
      name: "Done",
      selector: (row) => row.allOrderStatus[3],
      sortable: true,
    },
    {
      name: "Kunden",
      selector: (row) => row.customers,
      sortable: true,
    },
    {
      name: "Aktion",
      selector: (row) => customCell(row, click),
      sortable: true,
    },
  ];

  const getRole = localStorage.getItem("role");
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("token");
  const [data, setData] = useState([]);

  const allemp = () => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/employee/get/all`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setIsLoaded(true);
        setData(res.data.data);
        // toast.success(res.data.message, { toastId: "unique-random-text-xAu9C9-" })
      })
      .catch((res) => {
        setIsLoaded(true);
        toast.error(res.response.message);
      });
  };
  useEffect(() => {
    allemp();
  }, []);

  // delete employee
  const click = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Möchten Sie diesen Datensatz löschen?",
      buttons: [
        {
          label: "Ja",
          onClick: () => deletemp(id),
        },
        {
          label: "Nein",
        },
      ],
    });
  };

  const deletemp = (id) => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/user/remove/${id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        toast.success(res.data.message, {
          toastId: "unique-random-text-xAu9C9-",
        });
        allemp();
      })
      .catch((res) => {
        toast.error(res.response.data.message);
      });
  };
  const CustomEmptyState = () => (
    <div className="custom-empty-state p-3">Keine Einträge vorhanden</div>
  );
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <img src={"https://i.gifer.com/VAyR.gif"} className="loader" />;
  } else {
    return (
      <>
        <div className="main-body " id="root1">
          <div
            className="employee-header d-flex div position-relative"
            style={{ zIndex: "12" }}
          >
            <div className="d-flex my-auto">
              <i class="bi bi-person-square fs-2"></i>
              <h3>Mitarbeiter</h3>
            </div>

            <Link to="NewEmployee" className="order-btn">
              Mitarbeiter anlegen
            </Link>
          </div>

          <div
            className=" datatable position-relative"
            style={{ zIndex: "12" }}
          >
            <div id="admin-dashboarddata">
              <DataTable
                columns={columns}
                data={data}
                noHeader={true}
                pagination
                paginationPerPage={20}
                paginationComponentOptions={{
                  rowsPerPageText: "Anzahl/Seite:",
                  rangeSeparatorText: "von",
                }}
                paginationRowsPerPageOptions={[20, 30, 40]}
                noDataComponent={<CustomEmptyState />}
                theme="solarized"
                onRowClicked={(row) => {
                  if (getRole == 1) {
                    navigate(`/admin/employee/updateemployee/${row.id}`);
                  } else if (getRole == 2) {
                    navigate(`/employee/employee/updateemployee/${row.id}`);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </>
    );
  }
};
export default Employees;
