import React, { useEffect, useState } from "react";
import "./newcustomer.css";
import "../order/neworder.css";
import NewCustomerRegistrationSchma from "./NewCustomerRegistrationSchma";
import { useFormik } from "formik";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import jQuery from "jquery";
import { useNavigate } from "react-router-dom";
import Preview from "../../../Main/Registration/priview";

const NewCustomer = () => {
  // dropdown
  (function ($) {
    var CheckboxDropdown = function (el) {
      var _this = this;
      this.isOpen = false;
      this.areAllChecked = false;
      this.$el = $(el);
      this.$label = this.$el.find(".dropdown-label");
      this.$checkAll = this.$el.find('[data-toggle="check-all"]').first();
      this.$inputs = this.$el.find('[type="checkbox"]');

      this.onCheckBox();

      this.$label.on("click", function (e) {
        e.preventDefault();
        _this.toggleOpen();
      });

      this.$checkAll.on("click", function (e) {
        e.preventDefault();
        _this.onCheckAll();
      });

      this.$inputs.on("change", function (e) {
        _this.onCheckBox();
      });
    };

    CheckboxDropdown.prototype.onCheckBox = function () {
      this.updateStatus();
    };

    CheckboxDropdown.prototype.updateStatus = function () {
      var checked = this.$el.find(":checked");

      this.areAllChecked = false;
      this.$checkAll.html("Alle überprüfen");

      if (checked.length <= 0) {
        this.$label.html("Mitarbeiter zuordnen");
      } else if (checked.length === 1) {
        this.$label.html(checked.parent("label").text());
      } else if (checked.length === this.$inputs.length) {
        this.$label.html("Alle ausgewählt");
        this.areAllChecked = true;
        this.$checkAll.html("Alle deaktivieren");
      } else {
        this.$label.html(checked.length + "Ausgewählt");
      }
    };

    CheckboxDropdown.prototype.onCheckAll = function (checkAll) {
      if (!this.areAllChecked || checkAll) {
        this.areAllChecked = true;
        this.$checkAll.html("Alle deaktivieren");
        this.$inputs.prop("checked", true);
      } else {
        this.areAllChecked = false;
        this.$checkAll.html("Alle überprüfen");
        this.$inputs.prop("checked", false);
      }

      this.updateStatus();
    };

    CheckboxDropdown.prototype.toggleOpen = function (forceOpen) {
      var _this = this;

      if (!this.isOpen || forceOpen) {
        this.isOpen = true;
        this.$el.addClass("on");
        $(document).on("click", function (e) {
          if (!$(e.target).closest("[data-control]").length) {
            _this.toggleOpen();
          }
        });
      } else {
        this.isOpen = false;
        this.$el.removeClass("on");
        $(document).off("click");
      }
    };

    var checkboxesDropdowns = document.querySelectorAll(
      '[data-control="checkbox-dropdown"]'
    );
    for (var i = 0, length = checkboxesDropdowns.length; i < length; i++) {
      new CheckboxDropdown(checkboxesDropdowns[i]);
    }
  })(jQuery);
  // dropdown end
  const nevigate = useNavigate();
  const initialValues = {
    email: "",
    fname: "",
    lname: "",
    password: "",
    company: "",
    street: "",
    postal: "",
    country: "",
    file: null,
    repeatpassword: "",
    ust: "",
    number: "",
    city: "",
    payment: "",
    postalNum: "",
    ustId: "",
  };

  const token = localStorage.getItem("token");

  // employee api
  const [employeedata, setemployeeData] = useState([]);
  useEffect(() => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/employee/get/all`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setemployeeData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [cdata, setcData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getRole = localStorage.getItem("role");
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: NewCustomerRegistrationSchma,
    onSubmit: (data) => {
      setIsLoading(true); // Start loading
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/signup`,
        data: { ...data, isAdmin: true },
        headers: {
          authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      })
        .then(function (res) {
          setcData(res);
          setTimeout(() => {
            toast.success(res.data.message, {
              toastId: "unique-random-text-xAu9C9-",
            });
          }, 1000);

          if (getRole == 1) {
            nevigate("/admin/customer");
          } else {
            nevigate("/employee/customer");
          }
        })
        .catch(function (res) {
          toast.error(res.response.data.message);
        })
        .finally(() => {
          setIsLoading(false); // Stop loading, whether success or error
        });
    },
  });
  return (
    <>
      <div className="main-body newcustomer-main" id="root1">
        <form
          action="post"
          className="position-relative"
          style={{ zIndex: "12" }}
          onSubmit={handleSubmit}
        >
          <div className="newcustomer-header div ">
            {values.file && <Preview file={values.file} />}
            <div className="row w-100">
            <div className="col-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 pt-2">
              <input
                type="text"
                name="fname"
                value={values.fname}
                placeholder="Vorname..."
                className="customer-input"
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <div className="error ps-2 h-0">
                {errors.fname && touched.fname ? (
                  <small className="form-error">{errors.fname}</small>
                ) : null}
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 pt-2 lname">
              <input
                type="text"
                name="lname"
                value={values.lname}
                placeholder="Nachname..."
                className="customer-input"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div className="error ps-2 h-0">
                {errors.lname && touched.lname ? (
                  <small className="form-error">{errors.lname}</small>
                ) : null}
              </div>
            </div>
            </div>

            <button
              type="submit"
              onClick={handleSubmit}
              className="order-btn "
              disabled={isLoading}
            >
              {isLoading
                ? "Wird bearbeitet..."
                : // Replace with your loader icon
                  "Speichern"}
            </button>
 
            {/* <button className='order-btn' type='submit'><i className="fa-solid fa-print pe-1"></i>Safe</button> */}
    
          </div>
          <div className=" div">
            <div className="newcustomer-body gy-3 row pt-5 pb-5">
              <div className="col-md-6">
                <div className="field row ">
                  <div className="col-lg-3 col-md-12 d-flex">
                    <label className="customer-label">
                      E-Mail :<small>*</small>
                    </label>
                  </div>
                  <div className="col-md-12 col-lg-9">
                    <input
                      type="email"
                      name="email"
                      value={values.email}
                      placeholder="E-Mail..."
                      className="customer-input "
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="error">
                    {errors.email && touched.email ? (
                      <small className="form-error">{errors.email}</small>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="field row ">
                  <div className="col-md-12 col-lg-3 d-flex">
                    <label className="customer-label">
                      Mitarbeiter: <small>*</small>
                    </label>
                  </div>
                  <div className="col-md-12 col-lg-9">
                    <div
                      className=" dropdown "
                      data-control="checkbox-dropdown"
                    >
                      <label className="dropdown-label text-dark">Wahlen</label>

                      <div className="dropdown-list custom-scrollbar">
                        {employeedata.length > 0 &&
                          employeedata.map((e) => {
                            return (
                              <label className=" admin-product-list">
                                <input
                                  type="checkbox"
                                  className="dropdown-group "
                                  name="employee"
                                  value={e.id}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {e.fname == "" ? "no Fname" : e.fname}
                                {e.lname == "" ? "no Lname" : e.lname}
                              </label>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="field row ">
                  <div className="col-md-12 col-lg-3 d-flex">
                    <label className="customer-label">
                      Passwort :<small>*</small>
                    </label>
                  </div>
                  <div className="col-md-12 col-lg-9">
                    <input
                      type="password"
                      name="password"
                      value={values.password}
                      placeholder="Passwort..."
                      className="customer-input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>

                  <div className="error">
                    {errors.password && touched.password ? (
                      <small className="form-error">{errors.password}</small>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="field row ">
                  <div className="col-md-12 col-lg-3 d-flex">
                    <label className="customer-label">
                      Passwort wiederholen:<small>*</small>
                    </label>
                  </div>
                  <div className="col-md-12 col-lg-9">
                    <input
                      type="password"
                      name="repeatpassword"
                      placeholder="Passwort..."
                      className="customer-input"
                      value={values.repeatpassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="error">
                    {errors.repeatpassword && touched.repeatpassword ? (
                      <small className="form-error">
                        {errors.repeatpassword}
                      </small>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="field row ">
                  <div className="col-md-12 col-lg-3 d-flex">
                    <label className="customer-label">
                      Profilbild : <small></small>
                    </label>
                  </div>
                  <div className="col-md-12 col-lg-9">
                    <div className="file-area">
                      <input
                        type="file"
                        // ref={fileRef}
                        name="file"
                        multiple="multiple"
                        className="customer-input"
                        onChange={(event) => {
                          setFieldValue("file", event.target.files[0]);
                        }}
                      />
                      <div className="customer-input d-flex">
                        <div className="success m-auto">Profilbild hochladen</div>
                      </div>
                      <div className="error">
                        {" "}
                        {errors.file && touched.file ? (
                          <small className="form-error">{errors.file}</small>
                        ) : null}{" "}
                      </div>
                    </div>
                  </div>
                  <div className="col-1 col-md-2 profile-img">
                    {values.file && <Preview file={values.file} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="div">
            <div className="newcustomer-body gy-3 row pt-5 pb-5">
              <div className="col-md-6">
                <div className="field row ">
                  <div className="col-lg-3 col-md-12 d-flex">
                    <label className="customer-label">
                      Unternehmen : <small>*</small>
                    </label>
                  </div>
                  <div className="col-md-12 col-lg-9">
                    <input
                      type="text"
                      name="company"
                      value={values.company}
                      placeholder="Unternehmen..."
                      className="customer-input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="error">
                    {errors.company && touched.company ? (
                      <small className="form-error">{errors.company}</small>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="field row ">
                  <div className="col-lg-3 col-md-12 d-flex">
                    <label className="customer-label">
                      USt-IdNr. :<small></small>
                    </label>
                  </div>
                  <div className="col-md-12 col-lg-9">
                    <input
                      type="text"
                      name="ustId"
                      placeholder="USt-IdNr..."
                      className="customer-input"
                      value={values.ustId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="error">
                    {/* {errors.ustId && touched.ustId ? (
        <small className="form-error">{errors.ustId}</small>
      ) : null} */}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="field row ">
                  <div className="col-lg-3 col-md-12 d-flex">
                    <label className="customer-label">
                      Straße :<small>*</small>
                    </label>
                  </div>
                  <div className="col-md-12 col-lg-9">
                    <input
                      type="text"
                      name="street"
                      value={values.street}
                      placeholder="Straße..."
                      className="customer-input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="error">
                    {errors.street && touched.street ? (
                      <small className="form-error">{errors.street}</small>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="field row ">
                  <div className="col-lg-3 col-md-12 d-flex">
                    <label className="customer-label">
                      Nummer: <small>*</small>
                    </label>
                  </div>
                  <div className="col-md-12 col-lg-9">
                    <input
                      type="text"
                      name="number"
                      placeholder="Nummer..."
                      className="customer-input"
                      value={values.number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="error">
                    {errors.number && touched.number ? (
                      <small className="form-error">{errors.number}</small>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="field row ">
                  <div className="col-lg-3 col-md-12 d-flex">
                    <label className="customer-label">
                      Postleitzahl :<small>*</small>
                    </label>
                  </div>
                  <div className="col-md-12 col-lg-9">
                    <input
                      type="text"
                      name="postalNum"
                      value={values.postalNum}
                      placeholder="Postleitzahl..."
                      className="customer-input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="error">
                    {errors.postalNum && touched.postalNum ? (
                      <small className="form-error">{errors.postalNum}</small>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="field row ">
                  <div className="col-lg-3 col-md-12 d-flex">
                    <label className="customer-label">
                      Stadt :<small>*</small>
                    </label>
                  </div>
                  <div className="col-md-12 col-lg-9">
                    <input
                      type="text"
                      name="city"
                      placeholder="Stadt..."
                      className="customer-input"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="error">
                    {errors.city && touched.city ? (
                      <small className="form-error">{errors.city}</small>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="field row ">
                  <div className="col-lg-3 col-md-12 d-flex">
                    <label className="customer-label">
                      Land :<small>*</small>
                    </label>
                  </div>
                  <div className="col-md-12 col-lg-9">
                    <input
                      type="text"
                      name="country"
                      value={values.country}
                      placeholder="Land..."
                      className="customer-input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="error">
                    {errors.country && touched.country ? (
                      <small className="form-error">{errors.country}</small>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default NewCustomer;
