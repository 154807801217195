import React, { useEffect, useState } from "react";
import "./addcourse.css";
import bulb from "../../../Assets/menuicon/Group.png";
import { useFormik } from "formik";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import AddCourseSchema from "./AddCourseSchema";

const AddCourse = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem("token");
    const queryParams = new URLSearchParams(location.search);
    // const courseId = queryParams.get('course_id');

    const initialValues = {
        coursename: "",
        coursedesc: "",
    };

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        resetForm,
    } = useFormik({
        initialValues: initialValues,
        validationSchema: AddCourseSchema,
        onSubmit: (values) => {
            // console.log(values);

            const dataToSend = {
                coursename: values.coursename,
                coursedesc: values.coursedesc,
            };

            // console.log(dataToSend);

            axios({
                method: "POST",
                url: `${process.env.REACT_APP_BASE_URL}/course/add`,
                data: dataToSend,
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    // console.log(res.data);
                    if (res.data.message == "Course added successfully") {
                        toast.success(res.data.message);
                        resetForm();
                        navigate("/admin/course");
                    } else {
                        toast.error("Failed to add course");
                    }
                })
                .catch((error) => {
                    toast.error(error.response.data.message);
                });
        }
    });

    // get course
    // const [courseName, setCourseName] = useState('');
    // const updatecourse = () => {
    //     axios({
    //         method: "POST",
    //         url: `${process.env.REACT_APP_BASE_URL}/update/course`,
    //         data: {
    //             courseId: courseId,
    //         },
    //         headers: {
    //             authorization: `Bearer ${token}`,
    //         },
    //     })
    //         .then((res) => {
    //             // console.log(res.data.data);
    //             const courseName = res.data.data.map(item => item.course_name);
    //             console.log(courseName);
    //             setCourseName(courseName);
    //         })
    //         .catch((res) => {
    //             toast.error(res.response.message);
    //         });
    // };
    // useEffect(() => {
    //     updatecourse();
    // }, []);

    return (
        <>
            <div className=" main-body" id="root1">
                <form onSubmit={handleSubmit} className="add-course-form">
                    <div className="order-header neworder-header justify-content-between d-flex div">
                        <div className="d-flex order-header-section">
                            <img className="my-auto" src={bulb} width={35} height={45} alt="Pen Icon" />
                            <h3 className="me-2">Neuer Kurs</h3>
                        </div>
                        <div className="d-flex save-course-btn">
                            <button type="submit" className="neworder-btn ">Speichern</button>
                        </div>
                    </div>

                    <div className="div order_box add-course-box">
                        <div className="description">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-12 first_label d-flex ">
                                            <label className="order-label ">Course Name: <small>*</small></label>
                                        </div>
                                        <div className="col-lg-9 col-md-12 d-flex first_input justify-content-center">
                                            <input
                                                type="text"
                                                name="coursename"
                                                value={values.coursename}
                                                placeholder="Course Name"
                                                className="order_input"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="error">
                                        {errors.coursename && touched.coursename ? (
                                            <small className="form-error">{errors.coursename}</small>
                                        ) : null}
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-lg-3 col-md-12 first_label d-flex ">
                                            <label className="order-label ">Course Description: <small>*</small></label>
                                        </div>
                                        <div className="col-lg-9 col-md-12 d-flex first_input justify-content-center">
                                            <input
                                                type="text"
                                                name="coursedesc"
                                                value={values.coursedesc}
                                                placeholder="Course Description"
                                                className="order_input"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="error">
                                        {errors.coursedesc && touched.coursedesc ? (
                                            <small className="form-error">{errors.coursedesc}</small>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </>
    )
}

export default AddCourse;