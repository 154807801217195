import React, { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useParams } from "react-router-dom";
import "./email.css";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { IconContext } from "react-icons";
import { useFormik } from "formik";
import Emailverify from "./Emailverify";
import MyCustomUploadAdapterPlugin from './MyCustomUploadAdapterPlugin';

const Edit_email_template = () => {
  const token = localStorage.getItem("token");
  const { id } = useParams();
  const editorRef = useRef(null);
  const [editorContent, setEditorContent] = useState("");

  function handleEditorChange(content, editor) {
    setEditorContent(editor.getData());
  }
  const [subject, setSubject] = useState([]);
  const [headers, setHeader] = useState();

  function saveContentToDB() {
    console.log(headers);
    axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_BASE_URL}/send/text/${id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
      data: { text: editorContent, header: headers },
    })
      .then((res) => {
        toast.success(res.data.message, {
          toastId: "unique-random-text-xAu9C9-",
        });
      })
      .catch((res) => {
        toast.error(res.response.message);
      });
  }

  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  // email api
  const [templatedata, setTemplatedata] = useState([]);
  const [emailtitle, setEmailTitle] = useState();
  const [emailtype, setEmailType] = useState();
  const [eventaccept, seteventaccept] = useState([]);
  const [createevent, setcreateevent] = useState([]);
  const [createorder, setcreateorder] = useState([]);
  const [orderstatus, setorderstatus] = useState([]);
  const [registarion, setregistarion] = useState([]);
  const [newoder, setNewOrder] = useState([]);

  useEffect(() => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/fatch/template/${id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setIsLoaded(true);
        setSubject(res.data.data.header);
        setEmailTitle(res.data.data.email_title);
        setEmailType(res.data.data.email_type);
        setTemplatedata(res.data.data.email_content);
        setEditorContent(res.data.data.email_content);
        seteventaccept(res.data.data.static_var.event_accept);
        setcreateevent(res.data.data.static_var.create_new_event);
        setcreateorder(res.data.data.static_var.chat_message);
        setorderstatus(res.data.data.static_var.order_status);
        setregistarion(res.data.data.static_var.registration);
        setNewOrder(res.data.data.static_var.create_new_order);
        // toast.success(res.data.message, { toastId: "unique-random-text-xAu9C9-" })
      })
      .catch((res) => {
        setIsLoaded(true);
        toast.error(res.response.message);
      });
  }, []);

  // on submit
  const initialValues = {
    header: subject,
  };

  const [isLoading, setIsLoading] = useState(false);
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Emailverify,
    enableReinitialize: true,
    onSubmit: (data) => {
      setIsLoading(true); // Start loading
      axios({
        method: "PATCH",
        url: `${process.env.REACT_APP_BASE_URL}/send/text/${id}`,
        headers: {
          authorization: `Bearer ${token}`,
        },
        data: { text: editorContent, ...data },
      })
        .then((res) => {
          toast.success(res.data.message, {
            toastId: "unique-random-text-xAu9C9-",
          });
        })
        .catch((res) => {
          toast.error(res.response.message);
        })
        .finally(() => {
          setIsLoading(false); // Stop loading, whether success or error
        });
    },
  });

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <img src={"https://i.gifer.com/VAyR.gif"} className="loader" />;
  } else {
    return (
      <>
        <div
          className="main-body position-relative"
          style={{ zIndex: "12" }}
          id="root1"
        >
          <div className="order-header email_template_head justify-content-between d-flex div">
            <div className="d-flex">
              <IconContext.Provider
                value={{ className: "shared-class", size: 40 }}
              >
                <BsFillEnvelopeFill />
              </IconContext.Provider>

              <h3>{emailtitle}</h3>
            </div>

            <button
              type="submit"
              onClick={handleSubmit}
              className="order-btn  template-btn"
              disabled={isLoading}
            >
              {isLoading
                ? "Wird bearbeitet..."
                : // Replace with your loader icon
                  "Speichern"}
            </button>
          </div>

          <div className="email_template_header div">
            <h2>Platzhalter</h2>
            {emailtype === "event_accept" ? (
              eventaccept.map((val) => {
                return <h6>{val}</h6>;
              })
            ) : emailtype === "chat_message" ? (
              createorder.map((val) => {
                return <h6>{val}</h6>;
              })
            ) : emailtype === "create_new_event" ? (
              createevent.map((val) => {
                return <h6>{val}</h6>;
              })
            ) : emailtype === "order_status" ? (
              orderstatus.map((val) => {
                return <h6>{val}</h6>;
              })
            ) : emailtype === "registration" ? (
              registarion.map((val) => {
                return <h6>{val}</h6>;
              })
            ) : emailtype === "create_new_order" ? (
              newoder.map((val) => {
                return <h6>{val}</h6>;
              })
            ) : (
              <h6>Kein E-Mail-Inhalt verfugbar.</h6>
            )}
          </div>

          <form action="" onSubmit={handleSubmit}>
            <div className="email-content1 div">
              <div className="allinputs div1 ">
                <label className="my-2">
                  {" "}
                  Betreff der E-Mail :<small>*</small>
                </label>
                <br />
                <input
                  type="text"
                  name="header"
                  value={values.header}
                  placeholder="Write E-Mail subject."
                  className="customer-input my-4"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="error">
                  {errors.header && touched.header ? (
                    <small className="form-error">{errors.header}</small>
                  ) : null}
                </div>
              </div>
              <CKEditor
                editor={Editor}
                data={templatedata}
                onChange={handleEditorChange}
                config={{
                  extraPlugins:[MyCustomUploadAdapterPlugin],
                  toolbar: {
                    items: [
                        'findAndReplace', 'selectAll', '|',
                        'heading', '|',
                        'bold', 'italic', 'removeFormat', '|',
                        'bulletedList', 'numberedList','|',
                        'outdent', 'indent', '|',
                        'undo', 'redo',
                        '-',
                        'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', '|',
                        'alignment', '|',
                        'link', 'insertImage', 'blockQuote', 'insertTable', 'mediaEmbed', 'htmlEmbed',
                        'sourceEditing'
                    ],
                    shouldNotGroupWhenFull: true
                },
                list: {
                    properties: {
                        styles: true,
                        startIndex: true,
                        reversed: true
                    }
                },
                heading: {
                    options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                        { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
                    ]
                },
                placeholder: 'Write your code here',
                fontFamily: {
                    options: [
                        'default',
                        'DM Sans, sans-serif',
                        'Arial, Helvetica, sans-serif',
                        'Courier New, Courier, monospace',
                        'Georgia, serif',
                        'Lucida Sans Unicode, Lucida Grande, sans-serif',
                        'Tahoma, Geneva, sans-serif',
                        'Times New Roman, Times, serif',
                        'Trebuchet MS, Helvetica, sans-serif',
                        'Verdana, Geneva, sans-serif'
                    ],
                    supportAllValues: true
                },
                fontSize: {
                    options: [ 10, 12,13, 14, 'default', 18, 20, 22 ],
                    supportAllValues: true
                },
                htmlSupport: {
                    allow: [
                        {
                            name: /.*/,
                            attributes: true,
                            classes: true,
                            styles: true
                        }
                    ]
                },
                htmlEmbed: {
                    showPreviews: true
                },
                link: {
                    decorators: {
                        addTargetToExternalLinks: true,
                        defaultProtocol: 'https://',
                        toggleDownloadable: {
                            mode: 'manual',
                            label: 'Downloadable',
                            attributes: {
                                download: 'file'
                            }
                        }
                    }
                },
                mention: {
                    feeds: [
                        {
                            marker: '@',
                            feed: [
                                '@apple', '@bears', '@brownie', '@cake', '@cake', '@candy', '@canes', '@chocolate', '@cookie', '@cotton', '@cream',
                                '@cupcake', '@danish', '@donut', '@dragée', '@fruitcake', '@gingerbread', '@gummi', '@ice', '@jelly-o',
                                '@liquorice', '@macaroon', '@marzipan', '@oat', '@pie', '@plum', '@pudding', '@sesame', '@snaps', '@soufflé',
                                '@sugar', '@sweet', '@topping', '@wafer'
                            ],
                            minimumCharacters: 1
                        }
                    ]
                },
                }}
              />
            </div>
            {/* <button type="submit" className="order-btn m-auto template-btn">Safe</button> */}
          </form>
        </div>

        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </>
    );
  }
};
export default Edit_email_template;
