import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "./order.css";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import DataTable, { createTheme } from "react-data-table-component";
import { useFormik } from "formik";
import styled from "styled-components";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import dashboardIcon from "../../../Assets/menuicon/dashboard1.png";

const StyledCell = styled.div`
  &.Standard {
    border-radius: 755.494px;
    background: rgba(255, 255, 255, 0.2);
    font-family: Poppins;
    width: 120px;
    font-size: 16px;
    padding: 3px 0px;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
  &.Hoch {
    border-radius: 755.494px;
    background: #fff;
    text-align: center;
    color: #000;
    padding: 3px 0px;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 120px;
  }
  &.Neuer {
    border-radius: 75px;
    background: rgba(255, 255, 255, 0.2);
    width: 204px;
    height: 31px;
    padding: 3px;
    font-size: 16px;
    white-space: nowrap;

    height: 31px;
    text-align: center;
  }
  &.Wird {
    border-radius: 75px;
    width: 204px;
    background: linear-gradient(
      92deg,
      rgba(192, 222, 96, 0.2) 0.85%,
      rgba(96, 213, 222, 0.2) 110.38%
    );
    padding: 3px 6px;
    font-size: 16px;
    white-space: nowrap;

    text-align: center;
  }
  &.Abgeschlossen {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 75px;
    width: 200px;
    padding: 3px 35px;
    font-size: 16px;
    height: 31px;
    text-align: center;
    white-space: nowrap;
  }
  &.Feedback {
    border: 1px solid transparent;
    background: linear-gradient(#070707, #070707) padding-box, linear-gradient(45deg, #57bac2, #a6dc83) border-box;

    border-radius: 75px;
    width: 200px;
    padding: 3px 35px;
    font-size: 16px;
    height: 31px;
    text-align: center;
    white-space: nowrap;
  }
`;

function getPriorityClass(orderpriority) {
  if (orderpriority == 1) return "Hoch";
  else if (orderpriority == 0) return "Standard";
}

function getStatusClass(orderstatus) {
  if (orderstatus == 1) return "Neuer Auftrag";
  else if (orderstatus == 2) return "Wird bearbeitet";
  else if (orderstatus == 3) return "Abgeschlossen";
  else if (orderstatus == 4) return "Feedback offen";
  return "low";
}
function getByTitle(title, orderstatus) {
  if (orderstatus == 1) {
    return title;
  } else if (orderstatus == 2) return title;
  else if (orderstatus == 3) return title;
  else if (orderstatus == 4) return title;

  return "low";
}
function updatestatus(update_status_admin) {
  if (update_status_admin === true)
    return <i class="bi bi-envelope-exclamation-fill warninng"></i>;
  else if (update_status_admin == 2)
    return <i class="bi bi-envelope-exclamation-fill abcd"></i>;
  else if (update_status_admin === 0) return "";
}

const Order = () => {
  const customCell = (row, func2) => (
    <div className="d-flex">
      <div className="me-4 my-auto ">
        <Link to={`/admin/order/updateorder/${row.id}`}>
          <svg
            className="my-auto d-flex"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="Vector"
              d="M0 14.2505V18H3.74949L14.808 6.9415L11.0585 3.19201L0 14.2505ZM17.7076 4.0419C17.8003 3.94939 17.8738 3.83952 17.924 3.71856C17.9742 3.59761 18 3.46794 18 3.33699C18 3.20604 17.9742 3.07638 17.924 2.95542C17.8738 2.83446 17.8003 2.72459 17.7076 2.63209L15.3679 0.292404C15.2754 0.199713 15.1655 0.126176 15.0446 0.0760011C14.9236 0.0258265 14.794 0 14.663 0C14.5321 0 14.4024 0.0258265 14.2814 0.0760011C14.1605 0.126176 14.0506 0.199713 13.9581 0.292404L12.1284 2.12216L15.8778 5.87165L17.7076 4.0419Z"
              fill={`${row.orderstatus == 3 ? "rgb(120,120,120)" : "#BCBCBC"}`}
            />
          </svg>
        </Link>
      </div>
      <div className="my-auto">
        <svg
          className="my-auto d-flex"
          onClick={() => func2(row.id)}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Frame">
            <path
              id="Vector"
              d="M5.99878 19C5.99878 20.1 6.89878 21 7.99878 21H15.9988C17.0988 21 17.9988 20.1 17.9988 19V7H5.99878V19ZM18.9988 4H15.4988L14.4988 3H9.49878L8.49878 4H4.99878V6H18.9988V4Z"
              fill={`${row.orderstatus == 3 ? "rgb(120,120,120)" : "#BCBCBC"}`}
            />
          </g>
        </svg>
      </div>
    </div>
  );
  const column = [
    {
      name: "",
      cell: (row) => (
        <StyledCell className={updatestatus(row.update_status_admin)}>
          {updatestatus(row.update_status_admin)}
        </StyledCell>
      ),
      width: "2%",
      sortFunction: (a, b) => {
        // Customize the sort function based on your criteria
        // For example, sort alphabetically if orderstatus is not 3
        if (a.orderstatus !== 3 && b.orderstatus !== 3) {
          return a.ordername.localeCompare(b.ordername);
        }
        // For other cases (orderstatus is 3), maintain the existing order
        return 0;
      },
    },
    {
      name: "Priorität",
      sortable: true,
      cell: (row) => (
        <div className="d-flex  my_checkbox">
          <StyledCell className={getPriorityClass(row.orderpriority)}>
            {getPriorityClass(row.orderpriority)}
          </StyledCell>
          <input
            type="checkbox"
            defaultChecked={row.check_status}
            onChange={handleCheckboxChange}
            id={row.id}
            name="r-group"
          />
        </div>
      ),
      sortFunction: (a, b) => {
        // Customize the sort function based on your criteria
        // For example, sort alphabetically if orderstatus is not 3
        if (a.orderstatus !== 3 && b.orderstatus !== 3) {
          return a.ordername.localeCompare(b.ordername);
        }
        // For other cases (orderstatus is 3), maintain the existing order
        return 0;
      },
    },
    {
      name: "Kunde",
      selector: (row) =>
        row.customerName.map((val) => {
          return val.company;
        }),
      sortable: true,
      sortFunction: (a, b) => {
        // Customize the sort function based on your criteria
        // For example, sort alphabetically if orderstatus is not 3
        if (a.orderstatus !== 3 && b.orderstatus !== 3) {
          return a.ordername.localeCompare(b.ordername);
        }
        // For other cases (orderstatus is 3), maintain the existing order
        return 0;
      },
    },
    {
      name: "Titel",
      selector: (row) => row.ordername,
      sortFunction: (a, b) => {
        // Customize the sort function based on your criteria
        // For example, sort alphabetically if orderstatus is not 3
        if (a.orderstatus !== 3 && b.orderstatus !== 3) {
          return a.ordername.localeCompare(b.ordername);
        }
        // For other cases (orderstatus is 3), maintain the existing order
        return 0;
      },
      sortable: true,
    },
    {
      name: "Mitarbeiter",
      selector: (row) =>
        row.employeeName.map((val) => {
          return (
            <div className="Employee">
              <span>
                {val.fname} {val.lname}
                <br />
              </span>
            </div>
          );
        }),
      sortFunction: (a, b) => {
        // Customize the sort function based on your criteria
        // For example, sort alphabetically if orderstatus is not 3
        if (a.orderstatus !== 3 && b.orderstatus !== 3) {
          return a.employeeName.localeCompare(b.employeeName);
        }
        // For other cases (orderstatus is 3), maintain the existing order
        return 0;
      },
      sortable: true,
    },
    {
      name: "Nr.",
      selector: (row) => row.id,
      sortable: true,
      width: "5%",
      sortFunction: (a, b) => {
        // Customize the sort function based on your criteria
        // For example, sort alphabetically if orderstatus is not 3
        if (a.orderstatus !== 3 && b.orderstatus !== 3) {
          return a.ordername.localeCompare(b.ordername);
        }
        // For other cases (orderstatus is 3), maintain the existing order
        return 0;
      },
    },
    {
      name: "Datum",
      selector: (row) => new Date(row.createdAt),
      format: (row) => moment(row.createdAt).format("DD.MM.YYYY"),
      sortable: true,
      sortFunction: (a, b) => {
        if (a.orderstatus === 3 && b.orderstatus === 3) {
          // Maintain the existing order based on the date
          return new Date(a.createdAt) - new Date(b.createdAt);
        }
        if (a.orderstatus !== 3 && b.orderstatus !== 3) {
          return a.createdAt.localeCompare(b.createdAt);
        }
        return 0;
      },
    },
    {
      name: "Status",
      sortable: true,
      sortFunction: (a, b) => {
        // Customize the sort function based on your criteria
        // For example, sort alphabetically if orderstatus is not 3
        if (a.orderstatus !== 3 && b.orderstatus !== 3) {
          return a.ordername.localeCompare(b.ordername);
        }
        // For other cases (orderstatus is 3), maintain the existing order
        return 0;
      },
      cell: (row) => (
        <StyledCell className={getStatusClass(row.orderstatus)}>
          {getStatusClass(row.orderstatus)}
        </StyledCell>
      ),
    },
    {
      name: "Aktion",
      selector: (row) => customCell(row, click),
      sortable: true,
    },
  ];

  const getRole = localStorage.getItem("role");
  const getName = localStorage.getItem("name");

  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [orderdata, setOrderData] = useState([]);
  const token = localStorage.getItem("token");
  const [employeedata, setemployeeData] = useState([]);
  const [customerdata, setCustomerdata] = useState([]);
  const [empname, setEmpname] = useState();
  const navigate = useNavigate();
  // console.log(orderdata);
  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    // Here you can send the `id` and `checked` status to your backend for updating the database
    // console.log(checked);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/order/change_status`,
      data:{
        id:id,checked:checked
      },
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {

      
   
      })
      .catch((err) => {
        setIsLoaded(true);
        console.log(err);
      });
    // You can perform your database update logic here
  
    // If you need to update the UI based on the checkbox status, update the state
    // setIsChecked(checked);
  };
  useEffect(() => {
    // employee api
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/employee/get/all`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setemployeeData(res.data.data);
        setEmpname(res.data.data.fname);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    // customer api
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/user/all`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setIsLoaded(true);
        setCustomerdata(res.data.data);
      })
      .catch((err) => {
        setIsLoaded(true);
        console.log(err);
      });
  }, []);

  // all order
  // // get filter order
  const filterOrder = localStorage.getItem("filter");
  const filterOrders = JSON.parse(filterOrder);
  const allorder = (filter) => {
    if (filter) {
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/order/get/filter?orderstatus=${filterOrders.orderstatus}&employee=${filterOrders.employee}&orderpriority=${filterOrders.orderpriority}&customer=${filterOrders.customer}`,
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setOrderData(res.data.data);
          // toast.success(res.data.message, { toastId: "unique-random-text-xAu9C9-" })
        })
        .catch((res) => {
          toast.error(res.response.message);
        });
    } else {
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/order/get/filter?`,
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setOrderData(res.data.data);
          // Filter the data where orderstatus is 3
          const sortedData = res.data.data
            .filter((item) => item.orderstatus === 3)
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

          // Filter data with orderstatus !== 3
          const otherData = res.data.data.filter(
            (item) => item.orderstatus !== 3
          );

          // Combine two datasets into one variable
          const combinedData = [...otherData, ...sortedData];

          // Log the sortedData and combinedData
          console.log("Sorted Data:", sortedData);
          setOrderData(combinedData);
          //  console.log("Combined Data:", combinedData);
          // toast.success(res.data.message, { toastId: "unique-random-text-xAu9C9-" })
        })
        .catch((res) => {
          toast.error(res.response.message);
        });
    }
  };
  useEffect(() => {
    allorder(filterOrders);
  }, []);
  let initialValues;
  if (filterOrders) {
    initialValues = {
      orderstatus: filterOrders.orderstatus,
      employee: filterOrders.employee,
      customer: filterOrders.customer,
      orderpriority: filterOrders.orderpriority,
    };
  } else {
    initialValues = {
      orderstatus: "",
      employee: "",
      customer: "",
      orderpriority: "",
    };
  }

  const [isLoading, setIsLoading] = useState(false);
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      onSubmit: (chngedata) => {
        setIsLoading(true); // Start loading
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_BASE_URL}/order/get/filter?orderstatus=${chngedata.orderstatus}&employee=${chngedata.employee}&orderpriority=${chngedata.orderpriority}&customer=${chngedata.customer}`,
          data: chngedata,
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            setOrderData(res.data.data);
            localStorage.setItem("filter", JSON.stringify(chngedata));
            // localStorage.setItem("filter",JSON.stringify(res.data.data))
            // toast.success(res.data.message, { toastId: "unique-random-text-xAu9C9-" })
          })
          .catch((res) => {
            toast.error(res.response.data.message);
          })
          .finally(() => {
            setIsLoading(false); // Stop loading, whether success or error
          });
      },
    });

  // delete order
  const click = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Möchten Sie diesen Datensatz löschen?",
      buttons: [
        {
          label: "Ja",
          onClick: () => deletorder(id),
        },
        {
          label: "Nein",
        },
      ],
    });
  };

  const deletorder = (id) => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/order/remove/${id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        toast.success(res.data.message, {
          toastId: "unique-random-text-xAu9C9-",
        });
        allorder();
      })
      .catch((res) => {
        toast.error(res.response.data.message);
      });
  };
  // datatable component
  const CustomEmptyState = () => (
    <div className="custom-empty-state p-3">Keine Eintrage vorhanden</div>
  );
  useEffect(() => {
    // Add a custom class to rows with status 0
    const rows = document.querySelectorAll(".rdt_TableRow");
    rows.forEach((row) => {
      const inlineStyle = row.getAttribute("style");
      // console.log(inlineStyle);
      if (inlineStyle && inlineStyle.includes("background")) {
        row.classList.add("order_completed");
      }
    });
  }, [orderdata]);
  // // get filter order
  // const filterOrder = localStorage.getItem("filter")
  // const filterOrders = JSON.parse(filterOrder);
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <img src={"https://i.gifer.com/VAyR.gif"} className="loader" />;
  } else {
    return (
      <>
        <div className="main-body" id="root1">
          <div
            className="order-header position-relative d-flex div"
            style={{ zIndex: "12" }}
          >
            <img
              src={dashboardIcon}
              className="my-auto"
              width={50}
              height={41}
              alt="dashboard"
            />
            <h3>Auftrag</h3>
            <Link to="neworder" className="order-btn">
              Neuer Auftrag
            </Link>
          </div>
          <div className="order-body div">
            <form
              action=""
              className="position-relative"
              onSubmit={handleSubmit}
              style={{ zIndex: "12" }}
            >
              <div className="container">
                <div className="row d-flex justify-content-evenly">
                  <div className="col-md-6">
                    <label className="admin-lable">Mitarbeiter :</label>
                    <br />
                    {getRole == 1 ? (
                      <div className="position-relative">
                        <select
                          type="text"
                          name="employee"
                          value={values.employee}
                          placeholder="Mitarbeiter auswählen"
                          className="order-filter-select text-light form-select"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="" className="admin-select">
                            Mitarbeiter auswählen
                          </option>
                          {employeedata.map((e) => {
                            return (
                              <option value={e.id} className="admin-select">
                                {e.fname + " " + e.lname}
                              </option>
                            );
                          })}
                        </select>
                        <i class="bi h6 text-light bi-chevron-down my-auto pe-2 end-0  order-select-icon"></i>
                      </div>
                    ) : (
                      <input
                        type="text"
                        name="employee"
                        value={getName}
                        // value={values.employee}
                        className="user-input order-filter-select"
                      />
                    )}
                  </div>
                  <div className="col-md-6">
                    <label className="admin-lable">Status :</label>
                    <br />
                    <div className="position-relative">
                      <select
                        type="text"
                        name="orderstatus"
                        placeholder="Status auswählen"
                        className="order-filter-select text-light form-select"
                        value={values.orderstatus}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Status auswählen</option>
                        <option value="1">Neuer Auftrag</option>
                        <option value="2">Wird bearbeitet</option>
                        <option value="3">Abgeschlossen</option>
                        <option value="4">Feedback offen</option>
                      </select>
                      <i class="bi h6 text-light bi-chevron-down my-auto pe-2 end-0  order-select-icon"></i>
                    </div>
                  </div>
                  <div className="col-md-6 pt-2">
                    <label className="admin-lable">Priorität :</label>
                    <br />
                    <div className="position-relative">
                      <select
                        type="text"
                        name="orderpriority"
                        placeholder="Priorität auswählen"
                        className="order-filter-select form-select text-light"
                        value={values.orderpriority}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Priorität auswählen</option>
                        <option value="1">Hoch</option>
                        <option value="0">Standard</option>
                      </select>
                      <i class="bi h6 text-light bi-chevron-down my-auto pe-2 end-0  order-select-icon"></i>
                    </div>
                  </div>

                  <div className="col-md-6 pt-2">
                    <label className="admin-lable">Kunde :</label>
                    <br />
                    <div className="position-relative">
                      <select
                        type="text"
                        name="customer"
                        value={values.customer}
                        placeholder="Kunde auswählen"
                        className="order-filter-select form-select text-light"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Kunde auswählen</option>
                        {customerdata.map((e) => {
                          return <option value={e.id}>{e.company}</option>;
                        })}
                      </select>
                      <i class="bi h6 text-light bi-chevron-down my-auto pe-2 end-0  order-select-icon"></i>
                    </div>
                  </div>
                  <div className="btn-src col-2 ">
                    <button
                      type="submit"
                      className="btn-src-link"
                      disabled={isLoading}
                    >
                      {isLoading
                        ? "Wird bearbeitet..." // Replace with your loader icon
                        : "Aktualisieren"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="datatable">
            <div className="admin-dashboard">
              <div id="admin-dashboarddata">
                <DataTable
                  columns={column}
                  data={orderdata}
                  noHeader={true}
                  pagination={true}
                  paginationPerPage={20}
                  noDataComponent={<CustomEmptyState />}
                  paginationComponentOptions={{
                    rowsPerPageText: "Anzahl/Seite:",
                    rangeSeparatorText: "von",
                  }}
                  paginationRowsPerPageOptions={[20, 30, 40]}
                  conditionalRowStyles={[
                    {
                      when: (row) => row.orderstatus === 3,
                      style: {
                        background: "rgba(255, 255, 255, 0.04)",
                      },
                    },
                  ]}
                  onRowClicked={(row) => {
                    if (getRole == 1) {
                      navigate(`/admin/order/updateorder/${row.id}`);
                    } else if (getRole == 2) {
                      navigate(`/employee/order/updateorder/${row.id}`);
                    }
                  }}
                  theme="solarized"
                />
              </div>
            </div>
          </div>
        </div>

        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </>
    );
  }
};
export default Order;
