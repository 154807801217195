import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import "../../User/Userstyle/Userglobal.css";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable, { createTheme } from "react-data-table-component";
import dashboardIcon from "../../Assets/menuicon/dashboard1.png";
import axios from "axios";
import styled from "styled-components";
import moment from "moment/moment";
import { confirmAlert } from "react-confirm-alert";
// style components
const StyledCell = styled.div`
  &.Standard {
    border-radius: 755.494px;
    background: rgba(255, 255, 255, 0.2);
    font-family: Poppins;
    width: 120px;
    font-size: 16px;
    padding: 3px 0px;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
  &.Hoch {
    border-radius: 755.494px;
    background: #fff;
    text-align: center;
    color: #000;
    padding: 3px 0px;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 120px;
  }
  &.Neuer {
    border-radius: 75px;
    background: rgba(255, 255, 255, 0.2);
    width: 204px;
    height: 31px;
    padding: 3px;
    font-size: 16px;
    height: 31px;
    text-align: center;
  }
  &.Wird {
    border-radius: 75px;
    width: 204px;
    background: linear-gradient(
      92deg,
      rgba(192, 222, 96, 0.2) 0.85%,
      rgba(96, 213, 222, 0.2) 110.38%
    );
    padding: 3px 35px;
    font-size: 16px;

    text-align: center;
  }
  &.Abgeschlossen {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 75px;
    width: 204px;
    padding: 3px 39px;
    font-size: 16px;
    height: 31px;
    text-align: center;
  }
  &.Feedback {
    border: 1px solid transparent;
    background: linear-gradient(#070707, #070707) padding-box, linear-gradient(45deg, #57bac2, #a6dc83) border-box;

    border-radius: 75px;
    width: 200px;
    padding: 3px 35px;
    font-size: 16px;
    height: 31px;
    text-align: center;
    white-space: nowrap;
  }
`;

// conditional styling
function getPriorityClass(orderpriority) {
  if (orderpriority === 1) return "Hoch";
  else if (orderpriority === 0) return "Standard";
}
function getStatusClass(orderstatus) {
  if (orderstatus === 1) return "Neuer Auftrag";
  else if (orderstatus === 2) return "Wird bearbeitet";
  else if (orderstatus === 3) return "Abgeschlossen";
  else if (orderstatus == 4) return "Feedback offen";
  return "low";
}
function updatestatus(update_status) {
  if (update_status === true)
    return <i class="bi bi-envelope-exclamation-fill warninng"></i>;
  else if (update_status == 2)
    return <i class="bi bi-envelope-exclamation-fill abcd"></i>;
  else if (update_status === 0) return "";
}

export default function Dashboard() {
  const navigate = useNavigate();

  const customCell = (row, func2) => (
    <div className="d-flex">
      <div  className="me-4 my-auto " >
        <Link to={`/user/vieworder/${row.id}`}>
          <svg
           className="my-auto d-flex"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="Vector"
              d="M0 14.2505V18H3.74949L14.808 6.9415L11.0585 3.19201L0 14.2505ZM17.7076 4.0419C17.8003 3.94939 17.8738 3.83952 17.924 3.71856C17.9742 3.59761 18 3.46794 18 3.33699C18 3.20604 17.9742 3.07638 17.924 2.95542C17.8738 2.83446 17.8003 2.72459 17.7076 2.63209L15.3679 0.292404C15.2754 0.199713 15.1655 0.126176 15.0446 0.0760011C14.9236 0.0258265 14.794 0 14.663 0C14.5321 0 14.4024 0.0258265 14.2814 0.0760011C14.1605 0.126176 14.0506 0.199713 13.9581 0.292404L12.1284 2.12216L15.8778 5.87165L17.7076 4.0419Z"
              fill={`${row.orderstatus==3?'rgb(120,120,120)':'#BCBCBC'}`}
            />
          </svg>
        </Link>
      </div>
      <div className="my-auto">
        <svg
          className="my-auto d-flex"
          onClick={() => func2(row.id)}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Frame">
            <path
              id="Vector"
              d="M5.99878 19C5.99878 20.1 6.89878 21 7.99878 21H15.9988C17.0988 21 17.9988 20.1 17.9988 19V7H5.99878V19ZM18.9988 4H15.4988L14.4988 3H9.49878L8.49878 4H4.99878V6H18.9988V4Z"
              fill={`${row.orderstatus==3?'rgb(120,120,120)':'#BCBCBC'}`}
            />
          </g>
        </svg>
      </div>
    </div>
  );

  const columns = [
    {
      name: '',
      cell: (row) => ( 
        <StyledCell className={updatestatus(row.update_status)}>
          {updatestatus(row.update_status)}
        </StyledCell> 
      ),
      width: "2%",
      sortFunction: (a, b) => {
        // Customize the sort function based on your criteria
        // For example, sort alphabetically if orderstatus is not 3
        if (a.orderstatus !== 3 && b.orderstatus !== 3) {
          return a.ordername.localeCompare(b.ordername);
        }
        // For other cases (orderstatus is 3), maintain the existing order
        return 0;
      },
    },
    {
      name: "Priorität",
      width: "13%",
      sortable: true,

      cell: (row) => (
        <StyledCell className={getPriorityClass(row.orderpriority)}>
          {getPriorityClass(row.orderpriority)}
        </StyledCell>
      ),
      sortFunction: (a, b) => {
        // Customize the sort function based on your criteria
        // For example, sort alphabetically if orderstatus is not 3
        if (a.orderstatus !== 3 && b.orderstatus !== 3) {
          return a.ordername.localeCompare(b.ordername);
        }
        // For other cases (orderstatus is 3), maintain the existing order
        return 0;
      },
    },

    {
      name: "Auftrag",
      selector: (row) => row.ordername,
      width: "30%",

      sortable: true,
      sortFunction: (a, b) => {
        // Customize the sort function based on your criteria
        // For example, sort alphabetically if orderstatus is not 3
        if (a.orderstatus !== 3 && b.orderstatus !== 3) {
          return a.ordername.localeCompare(b.ordername);
        }
        // For other cases (orderstatus is 3), maintain the existing order
        return 0;
      },
    },

    {
      name: "Datum",
      selector: (row) => row.createdAt,
      width: "12%",
      format: (row) => moment(row.createdAt).format("DD.MM.YYYY"),
      sortable: true,
      sortFunction: (a, b) => {
        if (a.orderstatus === 3 && b.orderstatus === 3) {
          // Maintain the existing order based on the date
          return new Date(a.createdAt) - new Date(b.createdAt);
        }
        if (a.orderstatus !== 3 && b.orderstatus !== 3) {
          return a.createdAt.localeCompare(b.createdAt);
        }
        return 0;
      },
    },
    {
      name: "Status",
      width: "20%",
      sortable: true,
      cell: (row) => (
        <StyledCell className={getStatusClass(row.orderstatus)}>
          {getStatusClass(row.orderstatus)}
        </StyledCell>
      ),
      className: "abc",
      sortFunction: (a, b) => {
        // Customize the sort function based on your criteria
        // For example, sort alphabetically if orderstatus is not 3
        if (a.orderstatus !== 3 && b.orderstatus !== 3) {
          return a.ordername.localeCompare(b.ordername);
        }
        // For other cases (orderstatus is 3), maintain the existing order
        return 0;
      },
    },
    {
      name: "Nummer",
      selector: (row) => row.id,
      sortable: true,
      sortFunction: (a, b) => {
        // Customize the sort function based on your criteria
        // For example, sort alphabetically if orderstatus is not 3
        if (a.orderstatus !== 3 && b.orderstatus !== 3) {
          return a.ordername.localeCompare(b.ordername);
        }
        // For other cases (orderstatus is 3), maintain the existing order
        return 0;
      },
    },

    {
      name: "Aktion",
      selector: (row) => customCell(row, click),
      sortable: true,
      responsive: "sm",
    },
  ];

  const [error] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const getorder = () => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/order/get`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setIsLoaded(true);
        // toast.success(res.data.message, { toastId: "unique-random-text-xAu9C9-" })
        // console.log(res.data.data);
        setData(res.data.data);
      })
      .catch((res) => {
        toast.error(res.response.data.message, {
          toastId: "unique-random-text-xAu9C9-",
        });
        setIsLoaded(true);
      });
  };

  useEffect(() => {
    getorder();
  }, []);

  // delete order

  const click = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Möchten Sie diesen Datensatz löschen?",
      buttons: [
        {
          label: "Ja",
          onClick: () => deletorder(id),
        },
        {
          label: "Nein",
        },
      ],
    });
  };

  const deletorder = (id) => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/order/remove/${id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        toast.success(res.data.message, {
          toastId: "unique-random-text-xAu9C9-",
        });
        getorder();
      })
      .catch((res) => {
        toast.error(res.response.data.message);
      });
  };
  const CustomEmptyState = () => (
    <div className="custom-empty-state p-3">Keine Einträge vorhanden</div>
  );
  useEffect(() => {
    // Add a custom class to rows with status 0
    const rows = document.querySelectorAll('.rdt_TableRow');
    rows.forEach((row) => {
      const inlineStyle = row.getAttribute('style');
      // console.log(inlineStyle);
      if (inlineStyle && inlineStyle.includes('background')) {
        row.classList.add('order_completed');
      }
    });
  }, [data]);
 
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <img
        src={"https://i.gifer.com/VAyR.gif"}
        className="loader"
        alt="loaging"
      />
    );
  } else {
    return (
      <>
        <div className="main-body dashboard" id="root1">
          <div className="vieworder">
            <div className="order-header d-flex div">
              <img
                src={dashboardIcon}
                className="my-auto"
                width={50}
                height={41}
                alt="dashboard"
              />
              <h3>Dashboard</h3>
              <Link to="/user/neworder" className="order-btn">
                Neuer Auftrag
              </Link>
            </div>
          </div>
          <div className="datatable dashboard" id="dashboarddata">
            <DataTable
              columns={columns}
              data={data}
              pagination={true}
              paginationPerPage={20}
 
              paginationComponentOptions={{
                rowsPerPageText: "Anzahl/Seite:",
                rangeSeparatorText:'von'
              }}
              paginationRowsPerPageOptions={[20, 30, 40]}
              noHeader={true}
              theme="solarized"
              responsive={true}
              onRowClicked={(row) => {
                navigate(`/user/vieworder/${row.id}`);
              }}
              noDataComponent={<CustomEmptyState />}
              conditionalRowStyles={[
                {
                  when: (row) => row.orderstatus === 3,
                  style: {
                      background:'rgba(255, 255, 255, 0.04)',
                  },
                },
              ]}
              
            />
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </>
    );
  }
}
