
import React from 'react'
import { Outlet } from 'react-router-dom'
import Adminmenu from './Adminmenu'
import Notification from './Notification/Notification1'


export default function Adminlayout() {
  return (
    <>
        {/* <Notification/> */}
        <div className="admin-row">
        <Adminmenu />   
          <Outlet />
        </div>
          
    </>
  )
}

