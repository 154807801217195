import React, { useEffect, useState } from "react";
import "./myaccount.css";
import { useFormik } from "formik";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import AccountRegistrationSchema from "./AccountRegistrationSchema";
import Preview from "../../../Main/Registration/priview";
export default function MyAccount() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("token");
  const [data, setData] = useState([]);

  // fatch user
  const fatchuser = () => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/account/user/profile`,
      data: data,
      headers: {
        "content-type": "multipart/form-data",
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setData(res.data.data);
        setIsLoaded(true);
        // toast.success(res.data.message, { toastId: "unique-random-text-xAu9C9-" })
      })
      .catch((res) => {
        setIsLoaded(true);
        toast.error(res.response.message);
      });
  };

  useEffect(() => {
    fatchuser();
  }, []);

  const initialValues = {
    fname: data.fname,
    lname: data.lname,
    email: data.email,
    password: "",
    repeatpassword: "",
    file: data.userImg,
  };

  const [accountdata, setAccountData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: AccountRegistrationSchema,
    onSubmit: (data) => {
      setIsLoading(true); // Start loading
      axios({
        method: "PATCH",
        url: `${process.env.REACT_APP_BASE_URL}/account/user/profile`,
        data: data,
        headers: {
          authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
        },
      })
        .then((res) => {
          setAccountData(res);
          toast.success(res.data.message, {
            toastId: "unique-random-text-xAu9C9-",
          });
          fatchuser();
        })
        .catch((res) => {
          toast.error(res.response.data.message);
        })
        .finally(() => {
          setIsLoading(false); // Stop loading, whether success or error
        });
    },
  });
console.log(values);
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <img src={"https://i.gifer.com/VAyR.gif"} className="loader" />;
  } else {
    return (
      <>
        <div className="main-body" id="root1">
          <form action="post" className="position-relative" style={{zIndex:"12"}} onSubmit={handleSubmit}>
            <div className="account-header d-flex div">
              <i className="fa-solid fa-person fs-3 my-auto"></i>
              <h3 className="my-auto">Mein Konto</h3>
              <button
                type="submit"
                onClick={handleSubmit}
                className="order-btn"
                disabled={isLoading}
              >
                {isLoading
                  ? "Wird bearbeitet..."
                  : // Replace with your loader icon
                    "Speichern"}
              </button>
            </div>
            <div className="div">
              <div className="account-body ">
                <div className="row  gy-3">
                  <div className="col-md-6">
                    <div className="field row ">
                      <div className="col-lg-3 col-md-12 d-flex">
                        <label className="customer-label">
                          Vorname : <small>*</small>
                        </label>
                      </div>
                      <div className="col-md-12 col-lg-9">
                        <input
                          type="text"
                          name="fname"
                          value={values.fname}
                          placeholder="Vorname..."
                          className="customer-input"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="error">
                        {errors.fname && touched.fname ? (
                          <small className="form-error">{errors.fname}</small>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="field row ">
                      <div className="col-lg-3 col-md-12 d-flex">
                        <label className="customer-label">
                          Nachname:<small>*</small>
                        </label>
                      </div>
                      <div className="col-md-12 col-lg-9">
                        <input
                          type="text"
                          name="lname"
                          value={values.lname}
                          placeholder="Nachname..."
                          className="customer-input"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="error">
                        {errors.lname && touched.lname ? (
                          <small className="form-error">{errors.lname}</small>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="field row ">
                      <div className="col-lg-3 col-md-12 d-flex">
                        <label className="customer-label">
                          E-Mail :<small>*</small>
                        </label>
                      </div>
                      <div className="col-md-12 col-lg-9">
                        <input
                          type="email"
                          name="email"
                          value={values.email}
                          placeholder="E-Mail..."
                          className="customer-input"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="error">
                        {errors.email && touched.email ? (
                          <small className="form-error">{errors.email}</small>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="field row ">
                      <div className="col-lg-3 col-md-12 d-flex">
                        <label className="customer-label">
                          Position: <small></small>
                        </label>
                      </div>
                      <div className="col-md-12 col-lg-9">
                        <input
                          type="text"
                          className="customer-input"
                          placeholder="Position... "
                        />
                      </div>
                      <div className="error">
                        {errors.lname && touched.lname ? (
                          <small className="form-error">{errors.lname}</small>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="field row ">
                      <div className="col-lg-3 col-md-12 d-flex">
                        <label className="customer-label">
                          Passwort :<small></small>
                        </label>
                      </div>
                      <div className="col-md-12 col-lg-9">
                        <input
                          type="password"
                          name="password"
                          placeholder="Passwort..."
                          className="customer-input"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="error">
                        {errors.password && touched.password ? (
                          <small className="form-error">
                            {errors.password}
                          </small>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="field row ">
                      <div className="col-lg-3 col-md-12 d-flex">
                        <label className="customer-label">
                          Passwort wiederholen:<small></small>
                        </label>
                      </div>
                      <div className="col-md-12 col-lg-9">
                        <input
                          type="password"
                          name="repeatpassword"
                          placeholder="Passwort wiederholen..."
                          className="customer-input"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="error">
                        {errors.repeatpassword && touched.repeatpassword ? (
                          <small className="form-error">
                            {errors.repeatpassword}
                          </small>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="field row ">
                      <div className="col-lg-3 col-md-12 d-flex">
                        <label className="customer-label">
                          Profilbild: <small>*</small>
                        </label>
                      </div>
                      <div className="col-md-12 col-lg-7">
                        <div className="file-area">
                          <input
                            type="file"
                            name="file"
                            multiple="multiple"
                            placeholder="Write your E-Mail."
                            className="user-input"
                            onChange={(event) => {
                              setFieldValue("file", event.target.files[0]);
                            }}
                          />
                          <div className="customer-input profile-input">
                            <div className="success">
                              Profilbild
                              hochladen
                            </div>
                          </div>
                          <div className="error">
                            {" "}
                            {errors.file && touched.file ? (
                              <small className="form-error">
                                {errors.file}
                              </small>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-1 col-md-2 profile-img">
                          {values.file ? ( <img
                                src={`${process.env.REACT_APP_IMG_URL}/assets/profilepic/${values.file}`}
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  borderRadius: "50%",
                                }}
                              />) : (
                            <div
                              style={{
                                height: "50px",
                                width: "50px",
                                borderRadius: "50%",
                                background: "grey",
                              }}
                            ></div>
                          )}
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div class="row accounts-btn divone g-2">
            <div class="col-lg-6 ">
              <div class="col-md-12 contect-account-hr">
                <div className="container d-flex">
                 
                  <h3>Gehaltsabrechnungen </h3>
                </div>
                <div className="account-button pt-1">
                  <a
                    href="https://app.lexoffice.de/selfservices"
                    target="_blank"
                    className="order-btn w-auto d-flex justify-content-center"
                  >
                    Abrechnungen einsehen
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 ">
              <div class="col-md-12 contect-account-hr">
                <div className="container d-flex">
          
                  <h3>Personalabteilung kontaktieren</h3>
                </div>
                <div className=" account-button pt-1">
                  <a
                    href="mailto:buchhaltung@7i7.de"
                    className="w-auto order-btn d-flex justify-content-center"
                  >
                    {" "}
                    Personalabteilung kontaktieren
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </>
    );
  }
}
